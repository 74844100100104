import { __decorate } from "tslib";
import { PageBuilder as D24PageBuilder } from '@d24/page-builder';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import { ModuleWrapper } from './ModuleWrapper';
import { Inject } from '@plugin/inversify';
import { WindowType } from '@movecloser/front-core';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let PageBuilder = class PageBuilder extends Vue {
    constructor() {
        super(...arguments);
        /**
         * Elements to render inside the <PageBuilder>.
         */
        this.elements = [];
        /**
         * Wrapper component that should be used to display the contents of the <D24lock>.
         */
        this.elementWrapper = ModuleWrapper;
    }
    /**
     * Vue lifecycle hook.
     */
    created() {
        this.composeElements();
    }
    /**
     * Handles the @update event on the <PageBuilder>.
     * @param elements - Updated `elements` array.
     */
    onUpdate(elements) {
        this.updateElements(elements);
    }
    /**
     * Compose elements.
     */
    composeElements() {
        this.elements = this.modules.map(module => ({
            ...module,
            id: module.id || uuid()
        }));
    }
    /**
     * Updates the `elements` array.
     * @private
     */
    updateElements(elements) {
        this.elements = elements;
    }
    /**
     * Is device mobile.
     */
    get isMobile() {
        if (this.windowService && this.windowService.isClient) {
            return this.detectMobile(navigator.userAgent);
        }
        if (this.$ssrContext) {
            return this.detectMobile(this.$ssrContext.req.headers['user-agent']);
        }
        return true;
    }
    detectMobile(userAgent) {
        let isMobile = false;
        const mobileStrings = [
            'Android',
            'Opera Mini',
            'iPhone',
            'iPad',
            'Windows Phone'
        ];
        for (const s of mobileStrings) {
            isMobile = isMobile || userAgent.indexOf(s) >= 0;
        }
        return isMobile;
    }
};
__decorate([
    Inject(WindowType)
], PageBuilder.prototype, "windowService", void 0);
__decorate([
    Prop({
        type: Number,
        required: false,
        default: 6
    })
], PageBuilder.prototype, "columns", void 0);
__decorate([
    Prop({
        type: Array,
        required: false,
        default: () => []
    })
], PageBuilder.prototype, "modules", void 0);
__decorate([
    Prop({
        type: Object,
        required: false
    })
], PageBuilder.prototype, "parent", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    })
], PageBuilder.prototype, "name", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    })
], PageBuilder.prototype, "relatedService", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: 'main'
    })
], PageBuilder.prototype, "tag", void 0);
__decorate([
    Watch('modules')
], PageBuilder.prototype, "composeElements", null);
PageBuilder = __decorate([
    Component({
        name: 'PageBuilder',
        components: { D24PageBuilder }
    })
], PageBuilder);
export { PageBuilder };
export default PageBuilder;
