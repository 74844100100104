// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export var Hydration;
(function (Hydration) {
    Hydration["Never"] = "never";
    Hydration["OnInteraction"] = "on-interaction";
    Hydration["WhenIdle"] = "when-idle";
    Hydration["WhenVisible"] = "when-visible";
})(Hydration || (Hydration = {}));
