// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class SetupFailed extends Error {
    constructor(message, status, payload = {}) {
        super();
        this.message = message;
        this.status = status;
        this.payload = payload;
        this.name = 'SetupFailed';
    }
}
