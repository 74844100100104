import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Screen } from '@component/Screen';
import LiveFeedEntry from '../LiveFeedEntry/LiveFeedEntry.vue';
/*
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
let LiveFeedEntries = class LiveFeedEntries extends Vue {
};
__decorate([
    Prop({ required: true })
], LiveFeedEntries.prototype, "DeMarked", void 0);
__decorate([
    Prop({ required: true })
], LiveFeedEntries.prototype, "entries", void 0);
__decorate([
    Prop({ required: true })
], LiveFeedEntries.prototype, "relatedService", void 0);
LiveFeedEntries = __decorate([
    Component({
        name: 'LiveFeedEntries',
        components: { LiveFeedEntry, Screen }
    })
], LiveFeedEntries);
export { LiveFeedEntries };
export default LiveFeedEntries;
