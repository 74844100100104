import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
let FooterTop = class FooterTop extends Vue {
};
__decorate([
    Prop({
        type: Array,
        required: false
    })
], FooterTop.prototype, "links", void 0);
FooterTop = __decorate([
    Component({ name: 'FooterTop' })
], FooterTop);
export { FooterTop };
export default FooterTop;
