// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const routesFactory = () => {
    return [
        {
            path: '/subskrypcja/dziekujemy',
            name: 'thanks',
            component: () => import(/* webpackChunkName: "subscription" */ './views/ThanksView.vue')
        },
        {
            path: '/subskrypcja/rodzaje',
            name: 'choose.product',
            component: () => import(/* webpackChunkName: "subscription" */ './views/ChooseProductView.vue')
        },
        {
            path: '/subskrypcja/:term/podsumowanie',
            name: 'summary',
            component: () => import(/* webpackChunkName: "subscription" */ './views/SummaryView.vue')
        },
        {
            path: '/subskrypcja/:term/:transaction/status',
            name: 'status',
            component: () => import(/* webpackChunkName: "subscription" */ './views/StatusCheckView.vue')
        },
        {
            path: '/subskrypcja/:term/:first?',
            name: 'choose.payment',
            component: () => import(/* webpackChunkName: "subscription" */ './views/ChoosePaymentView.vue')
        },
        {
            path: '/subskrypcja/:oldTerm/:term/zmiana/podsumowanie',
            name: 'summary.upgrade',
            component: () => import(/* webpackChunkName: "subscription" */ './views/SummaryView.vue'),
            props: { upgrade: true }
        },
        {
            path: '/subskrypcja/:oldTerm/:term/zmiana/:first?',
            name: 'choose.payment.upgrade',
            component: () => import(/* webpackChunkName: "subscription" */ './views/ChoosePaymentView.vue'),
            props: { upgrade: true }
        }
    ];
};
