// Copyright © 2022 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
let FormRepository = class FormRepository extends Repository {
    async send(uuid, formData) {
        var _a, _b;
        const response = await this.connector.call('forms', 'send', { uuid: uuid }, { data: formData });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response));
        }
        return (_b = response.data) === null || _b === void 0 ? void 0 : _b.data;
    }
};
FormRepository = __decorate([
    Injectable()
], FormRepository);
export { FormRepository };
