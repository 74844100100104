// Copyright © 2021 Move Closer
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import merge from 'lodash/merge';
// import { locales as d24Messages } from '@d24/modules/front'
Vue.use(VueI18n);
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author VueI18n
 */
async function loadLocaleMessages() {
    const d24Messages = (await import(/* webpackChunkName: "i18n" */ '@d24/modules/locales')).locales;
    const messages = {};
    // const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const locales = {};
    for (const locale of ['pl', 'en']) {
        locales[locale] = await import(/* webpackChunkName: "i18n" */ `../locales/${locale}.json`);
    }
    Object.keys(locales).forEach(locale => {
        // locales.keys().forEach(key => {
        // const matches = key.match(/([A-Za-z0-9-_]+)\./i)
        // if (matches && matches.length > 1) {
        //   const locale = matches[1]
        messages[locale] = {};
        if (Object.prototype.hasOwnProperty.call(d24Messages, locale) &&
            typeof d24Messages[locale] === 'object') {
            messages[locale] = merge(messages[locale], d24Messages[locale]);
        }
        // messages[locale] = merge(messages[locale], locales[key])
        messages[locale] = merge(messages[locale], locales[locale]);
        // }
    });
    return messages;
}
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author VueI18n
 */
export const i18nOptions = {
    locale: process.env.VUE_APP_I18N_INITIAL_LOCALE || 'pl',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pl'
};
export async function createI18n() {
    return new VueI18n({
        ...i18nOptions,
        messages: await loadLocaleMessages()
    });
}
