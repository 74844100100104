// Copyright © 2021 Move Closer
/**
 * Filter that trim string to given length.
 *
 * @param  {String} value
 * @param length
 * @param breakWords - break words or leave only whole words
 * @param add
 * @return {String}
 */
export default function (value, length = 25, breakWords = false, add = '...') {
    if (value.length <= length) {
        return value;
    }
    let shortened = value.substring(0, length - 1);
    shortened = `${breakWords ? shortened : shortened.substring(0, shortened.lastIndexOf(' '))}`;
    if (shortened.search(/[^\w\s]/g) === shortened.length - 1) {
        shortened = shortened.substring(0, shortened.length - 1);
    }
    return `${shortened}${add}`;
}
