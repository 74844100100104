// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const NoAccessPath = '/brak-dostepu';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SubscriptionRequiredPath = '/kup-dostep';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const routesFactory = () => {
    return [
        {
            path: NoAccessPath,
            name: 'no-access',
            component: () => import(/* webpackChunkName: "NoAccessView" */ './views/NoAccessView.vue'),
            meta: { skipAuthModal: true }
        }, {
            path: SubscriptionRequiredPath,
            name: 'buy-subscription',
            redirect: { name: 'content.no-access' }
        }, {
            path: '/simple-preview/*',
            name: 'simple-preview',
            component: () => import(/* webpackChunkName: "SimplePreview" */ './views/SimplePreviewView.vue'),
            meta: { layout: 'clear' }
        }, {
            path: '/search',
            name: 'search',
            component: () => import(/* webpackChunkName: "SearchView" */ './views/SearchView.vue')
        }, {
            path: '*',
            name: 'content',
            component: () => import(/* webpackChunkName: "ContentView" */ './views/ContentView.vue')
        }
    ];
};
