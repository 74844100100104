// Copyright © 2021 Move Closer
export const feedEntryAdapterMap = {
    createdAt: 'createdAt',
    endedAt: 'endedAt',
    startedAt: 'startedAt',
    status: 'status',
    isNew: 'isNew',
    title: 'title',
    updatedAt: 'updatedAt'
};
