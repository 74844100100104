// Copyright © 2021 Move Closer
import { EventbusType } from '@movecloser/front-core';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { log } from '@support/log';
Vue.use(VueRouter);
/**
 * Creates the VueRouter instance with all add-ons.
 *
 * @param routes - Routes configuration from the `@AppModule`s.
 * @param container - IoC container.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const createRouter = (routes, container) => {
    const router = new VueRouter({
        base: process.env.BASE_URL,
        mode: 'history',
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }
            // Second condition occurs while redirecting from the OAuth providers.
            if (to.hash && !to.hash.startsWith('#_=_')) {
                return {
                    // offset: { x: 0, y: 0 }, - this offset might need some adjustments
                    selector: to.hash
                };
            }
            return { x: 0, y: 0 };
        }
    });
    let eventBus;
    try {
        eventBus = container.get(EventbusType);
    }
    catch (error) {
        if (process.env.VUE_APP_ENV === 'local') {
            throw new Error(error);
        }
        else {
            log(error, 'error');
        }
    }
    /**
     * Emit event on every route change.
     */
    router.afterEach((to, from) => {
        log(`[Router]: Changed from [${from.fullPath}] to [${to.fullPath}]`, 'debug');
        if (!eventBus) {
            return;
        }
        eventBus.emit('ui:router.changed', { from, to });
    });
    router.beforeEach((to, from, next) => {
        if (!eventBus) {
            next();
            return;
        }
        eventBus.emit('ui:router.beforeChange');
        next();
    });
    return router;
};
