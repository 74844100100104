// Copyright © 2021 Move Closer
import { Methods } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resources = {
    promotions: {
        prefix: 'promotions',
        methods: {
            apply: {
                url: 'reservePromoCode',
                method: Methods.Put,
                auth: true
            }
        }
    },
    subscriptions: {
        prefix: '',
        methods: {
            create: {
                url: 'subscriptions',
                method: Methods.Post,
                auth: true
            },
            begin: {
                url: 'subscriptions/{subscription}/{id}',
                method: Methods.Post,
                params: ['subscription', 'id'],
                auth: true
            },
            beginUpgrade: {
                url: 'subscriptions/upgrade',
                method: Methods.Post,
                auth: true
            },
            cancel: {
                url: 'subscriptions/{id}',
                method: Methods.Delete,
                params: ['id'],
                auth: true
            },
            restore: {
                url: 'subscriptions/restore/{id}/restore',
                method: Methods.Post,
                params: ['id'],
                auth: true
            },
            load: {
                url: 'subscriptions/{id}',
                method: Methods.Get,
                params: ['id'],
                auth: true
            },
            pricing: {
                url: 'subscription-pricing',
                method: Methods.Get,
                auth: true
            },
            register: {
                url: 'subscriptions/{id}',
                method: Methods.Post,
                params: ['id'],
                auth: true
            },
            upgrade: {
                url: 'subscriptions/upgrade/{subscription}',
                method: Methods.Post,
                params: ['subscription'],
                auth: true
            },
            status: {
                url: 'transactions/{id}',
                method: Methods.Get,
                params: ['id'],
                auth: true
            },
            paywall: {
                url: 'paywall/{pageId}/{driver}',
                method: Methods.Post,
                params: ['pageId', 'driver'],
                auth: true
            }
        }
    }
};
