// Copyright © 2021 Move Closer
export const NavigationServiceType = Symbol.for('INavigationService');
export var NavigationSlot;
(function (NavigationSlot) {
    NavigationSlot["FooterBottom"] = "footerBottom";
    NavigationSlot["FooterTop"] = "footerTop";
    NavigationSlot["Left"] = "left";
    NavigationSlot["RelatedSites"] = "relatedSites";
    NavigationSlot["Social"] = "social";
    NavigationSlot["Top"] = "top";
})(NavigationSlot || (NavigationSlot = {}));
export var OpenStrategy;
(function (OpenStrategy) {
    OpenStrategy["Blank"] = "blank";
    OpenStrategy["Self"] = "self";
})(OpenStrategy || (OpenStrategy = {}));
export var Target;
(function (Target) {
    Target["External"] = "external";
    Target["Internal"] = "internal";
})(Target || (Target = {}));
