// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export var AuthAction;
(function (AuthAction) {
    AuthAction["Booted"] = "booted";
    AuthAction["Refreshed"] = "refreshed";
    AuthAction["Refreshing"] = "refreshing";
})(AuthAction || (AuthAction = {}));
