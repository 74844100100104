// Copyright © 2021 Move Closer
import { TokenDriver } from '@movecloser/front-core';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const auth = {
    refreshThreshold: 600,
    tokenDriver: TokenDriver.Single,
    tokenName: 'd24_token',
    validThreshold: 500
};
