// Copyright © 2022 Move Closer
import { Methods } from '@movecloser/front-core';
export const resources = {
    forms: {
        prefix: '',
        methods: {
            send: {
                url: 'forms/{uuid}',
                params: ['uuid'],
                method: Methods.Post,
                auth: true
            }
        }
    }
};
