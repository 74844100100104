// Copyright © 2021 Move Closer
import { MappingTypes } from '@movecloser/front-core';
/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const authorAdapterMap = {
    id: 'id',
    avatar: 'avatar',
    name: {
        type: MappingTypes.Function,
        value: item => {
            return item.nickname;
        }
    }
};
