// Copyright © 2021 Move Closer
import { validateAppContext } from '@support/validate-app-context';
import { SetupServiceType } from '@service/setup';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function preventRedirectionLoop(entry, target) {
    return entry.replace(/^\/|\/$/g, '') ===
        target.replace('http://', '')
            .replace('https://', '')
            .replace(/^\/|\/$/g, '');
}
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
const InitSetupPlugin = {
    async created(context) {
        var _a, _b;
        validateAppContext(context);
        let entryPoint = context.isClient
            ? window.location.href : `${(_a = context.ssr) === null || _a === void 0 ? void 0 : _a.req.headers.host}${context.url}` || '';
        // NOTE! To prevent mismatches due to HTTP protocol, we need to remove it from entryPoint.
        if (entryPoint.startsWith('http')) {
            entryPoint = entryPoint.replace('http://', '').replace('https://', '');
        }
        // NOTE! This override is required for development process when early stage requires `client:ssr`.
        if (process.env.VUE_APP_ENV === 'local' && entryPoint.startsWith('localhost') && !!process.env.VUE_APP_SETUP_DOMAIN) {
            entryPoint = [
                process.env.VUE_APP_SETUP_DOMAIN,
                entryPoint
            ].join('.');
        }
        // NOTE! This line is disabled due to previous 'validateAppContext' check,
        // which guarantees that both 'app' & '$container' are available here.
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        const service = context.app.$container.get(SetupServiceType);
        try {
            await service.initApp(entryPoint, context.isClient);
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            context.app.$i18n.locale = service.getLocale();
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            context.app.$store.commit('toggleMaintenanceMode', service.isMaintenance());
        }
        catch (e) {
            if (e.status === 503) {
                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                context.app.$store.commit('toggleMaintenanceMode', service.isMaintenance());
                return;
            }
            if (![400, 404].includes(e.status)) {
                context.error(e.message, e.status);
            }
            // For all environments different than 'local' there's no need to run the app
            // without SSR so we can printout an error easily. But if the fallback redirection
            // ended up with an error, we should avoid too many redirections loop.
            const redirectionTarget = (_b = process.env.VUE_APP_SETUP_REDIRECT) !== null && _b !== void 0 ? _b : '/';
            if (!['local'].includes(process.env.VUE_APP_ENV || 'local') || preventRedirectionLoop(entryPoint, redirectionTarget)) {
                context.error(e.message, e.status);
            }
            context.redirect(redirectionTarget);
        }
    }
};
export default InitSetupPlugin;
