// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, ResourceActionFailed } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let PaywallService = class PaywallService {
    constructor(connector) {
        this.connector = connector;
    }
    async checkAccess(path = '', id) {
        var _a;
        const response = await this.connector.call('paywall', 'check', {}, { id, path });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, response.status);
        }
        return {
            status: { ...response.data.data },
            limits: response.data.meta
        };
    }
};
PaywallService = __decorate([
    Injectable()
], PaywallService);
export { PaywallService };
