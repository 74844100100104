// Copyright © 2021 Move Closer
import 'reflect-metadata';
import NoSSR from 'vue-no-ssr';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { clamp, focus, registerBootstrapDSL } from '@d24/modules/front';
import Configuration from '@plugin/configuration';
import { createApp } from '@/bootstrap/app';
// import { initSentry } from '@support/sentry'
// import { isNonDevelopmentEnv } from '@support/environment'
import Inversify from '@plugin/inversify';
import { limitSsrConsole } from '@support/limit-ssr-console';
import Logger from '@plugin/logger';
import { registerExtensions } from '@/shared/extensions';
import './scss/index.scss';
import App from './App.vue';
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    adapter: {
        loaded({ el }) {
            const srcset = el.getAttribute('data-srcset');
            if (srcset) {
                el.setAttribute('srcset', srcset);
            }
        }
    },
    attempt: 1,
    preLoad: 2.5
});
Vue.use(Configuration);
Vue.use(Inversify);
Vue.use(Logger);
Vue.use(VueMeta);
Vue.component('no-ssr', NoSSR);
Vue.directive('clamp', clamp);
Vue.directive('focus', focus);
registerExtensions(Vue);
limitSsrConsole();
registerBootstrapDSL('D24');
export default (context) => {
    return createApp(context).then(bootedApp => {
        const { configuration, container, i18n, router, store } = bootedApp;
        // if (isNonDevelopmentEnv()) {
        // initSentry()
        // }
        return new Vue({
            configuration,
            container,
            i18n,
            render: h => h(App),
            router,
            store
        });
    });
};
