// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const ErrorClearPlugin = {
    async beforeStart(context) {
        const { error, router } = context;
        router.beforeEach((to, from, next) => {
            error.clear();
            next();
        });
    }
};
export default ErrorClearPlugin;
