import { __decorate } from "tslib";
import { Addon, LiveFeedModuleUi } from '@d24/modules/front';
import { Collection } from '@movecloser/front-core';
import { Component, Watch } from 'vue-property-decorator';
import { buildMetaTags } from '@support/meta';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { FeedDirection, FeedEntriesRepositoryType, LiveFeedRepositoryType, LiveFeedStatus } from '../../../contracts';
import { LiveFeed as LiveFeedClass } from '../../../models/live-feed';
import { LiveFeedEntries } from './LiveFeedEntries/LiveFeedEntries.vue';
/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
let LiveFeed = class LiveFeed extends LiveFeedModuleUi {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.firstLoadDicts = {};
        this.entries = new Collection();
        this.entryId = '';
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.feed = new LiveFeedClass();
        this.feedId = null;
        this.meta = [];
        this.hasMore = true;
        this.perPage = 10;
        /**
         * Feed update timed (in ms)
         */
        this.timer = {
            Live: 5000,
            Wait: 10000
        };
        /**
         * Moment of component rendering
         */
        this.enteredAt = Math.floor(new Date().getTime() / 1000);
    }
    /**
     * isStatusNew
     */
    get isStatusNew() {
        return this.status === LiveFeedStatus.New;
    }
    // get sortedEntries (): FeedEntryModel[] {
    //   return this.entries.sort((a: FeedEntryModel, b: FeedEntryModel) => {
    //     return a.get('createdAt') - b.get('createdAt')
    //   })
    // }
    /**
     * Returns entries in order considering mode.
     */
    // get entriesToDisplay (): FeedEntryModel[] {
    //   return this.status === LiveFeedStatus.Live
    //     ? this.sortedEntries.reverse()
    //     : this.sortedEntries
    // }
    /**
     * Return the newest of entries
     */
    get newestEntry() {
        return this.entries[0];
    }
    /**
     * Return the oldest of entries
     */
    get oldestEntry() {
        return this.entries[this.entries.length - 1];
    }
    /**
     * Feed status
     */
    get status() {
        if (!this.feed) {
            return null;
        }
        return this.feed.status;
    }
    /**
     * Set `feed id` from provided properties
     */
    assignFeedId() {
        if (this.parent && this.parent.hasProperty(Addon.LiveFeed)) {
            const liveFeed = this.parent.getProperty(Addon.LiveFeed);
            this.feedId = typeof liveFeed === 'object' ? liveFeed.feedId : null;
        }
        else {
            this.isLoading = false;
            this.$logger('FeedId is not provided', 'error');
        }
    }
    /**
     * Initial live feed fetch
     */
    async fetchFeedDetails() {
        if (!this.feedId) {
            return;
        }
        this.isLoading = true;
        try {
            await this.fetchFeed();
            await this.fetchNewEntries();
        }
        catch (err) {
            this.$logger(err.status, 'error');
        }
        finally {
            this.isLoading = false;
        }
    }
    /**
     * Fetch feed info
     */
    async fetchFeed() {
        if (!this.feedId) {
            return;
        }
        this.feed = await this.liveFeedRepository.fetch(this.feedId);
    }
    /**
     * Load new entries in interval
     */
    async fetchNewEntries() {
        if (!this.feedId) {
            return;
        }
        const query = {
            mode: this.feed.status,
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: this.perPage
        };
        if (this.entries.length) {
            query[FeedDirection.After] = this.newestEntry.createdAt;
        }
        try {
            const newEntries = await this.feedEntriesRepository.entries(this.feedId, query);
            if (typeof window === 'undefined') {
                this.firstLoadDicts = newEntries.meta;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.relatedService.storeRelated(newEntries.meta);
            if (!newEntries.length) {
                return;
            }
            // Map new entries if there are existing ones
            if (this.entries.length) {
                this.entries = new Collection([
                    ...this.mapEntries(newEntries, 'isNew', true),
                    ...this.mapEntries(this.entries, 'isNew', false)
                ]);
                // Skip mapping if there are no entries (no new entry marker)
            }
            else {
                this.entries = new Collection(newEntries);
            }
            this.reloadTwitterAddons();
        }
        catch (err) {
            // Feed ended
            if (err.status === 409) {
                this.feed.status = LiveFeedStatus.Ended;
            }
            else {
                this.$logger(err, 'error');
            }
        }
    }
    /**
     * Load old entries in interval
     */
    async fetchOldEntries() {
        if (!this.feedId) {
            return 0;
        }
        const query = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: this.perPage
        };
        if (this.status === LiveFeedStatus.Live) {
            query.mode = this.feed.status;
        }
        if (this.entries.length) {
            if ((this.status === LiveFeedStatus.Ended && !this.feed.reverseOrder) || this.status === LiveFeedStatus.Live) {
                query[FeedDirection.Before] = this.oldestEntry.createdAt;
            }
            else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                query[FeedDirection.After] = this.oldestEntry.createdAt;
            }
        }
        try {
            const oldEntries = await this.feedEntriesRepository.entries(this.feedId, query);
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.relatedService.storeRelated(oldEntries.meta);
            // Nothing more to fetch
            if (oldEntries.length === 0) {
                this.hasMore = false;
            }
            this.entries.push(...oldEntries);
            this.reloadTwitterAddons();
            return oldEntries.length;
        }
        catch (err) {
            // Feed ended
            this.hasMore = false;
            this.$logger(err, 'error');
            return 0;
        }
    }
    /**
     * Clear existing interval
     * @
     */
    clearInterval() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    /**
     * Handle pending feed
     */
    handleLiveFeed() {
        this.clearInterval();
        this.interval = setInterval(() => {
            this.fetchNewEntries();
            this.fetchFeed();
        }, this.timer.Live);
    }
    /**
     * Handle ended feed
     */
    handleEndedFeed() {
        if (!this.entries.length) {
            this.fetchOldEntries();
        }
        this.clearInterval();
    }
    /**
     * Handle case when feed is about to start
     */
    handlePendingFeed() {
        this.interval = setInterval(() => {
            this.fetchNewEntries();
            this.fetchFeed();
        }, this.timer.Wait);
    }
    /**
     * Choose handler when feed status is revealed
     */
    handleStatusChange(status) {
        switch (status) {
            case LiveFeedStatus.Ended:
                this.handleEndedFeed();
                break;
            case LiveFeedStatus.New:
                this.handlePendingFeed();
                break;
            case LiveFeedStatus.Live:
                this.handleLiveFeed();
                break;
        }
    }
    /**
     * Map single property on entire collection
     */
    mapEntries(entries, key, value) {
        const toMap = [...entries];
        toMap.forEach(e => {
            e.set(key, value);
        });
        return toMap;
    }
    /**
     * Watch for feed status property
     */
    onStatusChanged(val) {
        if (typeof window !== 'undefined') {
            this.handleStatusChange(val);
        }
    }
    async scrollToEntry(entryId) {
        const hasFound = this.entries.some((entry) => entry.id === entryId);
        if (!hasFound) {
            const loadedEntries = await this.fetchOldEntries();
            if (loadedEntries > 0) {
                // There are other entries.
                this.$nextTick(() => this.scrollToEntry(entryId));
                return;
            }
            return;
        }
        const entry = document.getElementById(`entry-${entryId}`);
        if (typeof entry === 'undefined' || !entry) {
            return;
        }
        window.scrollTo({
            top: entry.getBoundingClientRect().bottom,
            behavior: 'smooth'
        });
    }
    reloadTwitterAddons() {
        if (typeof window === 'undefined' || !Object.prototype.hasOwnProperty.call(window, 'twttr')) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.$nextTick(() => window.twttr.widgets.load());
    }
    async resolveMetaWhenSharing(entryId) {
        var _a;
        const properties = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.get('properties');
        let feedMeta = {};
        try {
            // Check if live feed id is present in url
            // Try to fetch feed data if it it live article
            if (entryId && properties && properties.liveFeed) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const feedId = properties.liveFeed.feedId;
                if (feedId && entryId) {
                    const { model: entry, meta } = await this.feedEntriesRepository.single(feedId, String(entryId));
                    feedMeta = {
                        ogDescription: entry.content,
                        twitterDescription: entry.content,
                        ogImage: entry.image,
                        twitterImage: entry.image
                    };
                    this.meta = await buildMetaTags({ ...properties.meta, ...feedMeta }, meta.dicts);
                }
            }
        }
        catch (error) {
            this.$logger(error, 'error');
        }
    }
};
__decorate([
    Inject(FeedEntriesRepositoryType)
], LiveFeed.prototype, "feedEntriesRepository", void 0);
__decorate([
    Inject(LiveFeedRepositoryType)
], LiveFeed.prototype, "liveFeedRepository", void 0);
__decorate([
    Watch('status')
], LiveFeed.prototype, "onStatusChanged", null);
LiveFeed = __decorate([
    Component({
        name: 'LiveFeed',
        components: { LiveFeedEntries, Screen },
        metaInfo() {
            return this.meta ? { meta: this.meta } : {};
        },
        created() {
            this.assignFeedId();
        },
        async prefetch() {
            await this.fetchFeedDetails();
            // We have specified entry & we should consider one off two scenarios:
            //  1. SSR side - update META with entry details
            //  2. Client side - load & scroll to entry (mounted hook)
            if (typeof this.$route.query.entry === 'string' && this.$route.query.entry.length > 0 &&
                this.$ssrContext.isServer) {
                await this.resolveMetaWhenSharing(this.$route.query.entry);
            }
        },
        beforeMount() {
            if (typeof window !== 'undefined') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                this.relatedService.storeRelated(this.firstLoadDicts);
            }
        },
        mounted() {
            // We have specified entry & we should consider one off two scenarios:
            //  1. SSR side - update META with entry details
            //  2. Client side - load & scroll to entry (mounted hook)
            if (typeof this.$route.query.entry === 'string' && this.$route.query.entry.length > 0) {
                this.entryId = this.$route.query.entry;
                this.scrollToEntry(this.$route.query.entry);
            }
        },
        beforeDestroy() {
            this.clearInterval();
        }
    })
], LiveFeed);
export { LiveFeed };
export default LiveFeed;
