// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { NewsletterModuleUi } from '@d24/modules/front';
import { Inject } from '@plugin/inversify';
import { UserComServiceType } from '@service/usercom';
import { LocalStorage } from '@movecloser/front-core';
let NewsletterModuleUI = class NewsletterModuleUI extends NewsletterModuleUi {
    /**
     * get the current user
     */
    get user() {
        return this.$store.getters['profile/me'];
    }
    onSubmit(formData) {
        const userComId = LocalStorage.get('d24-user-com-id');
        this.eventBus.emit('newsletter-subscribe', {
            ...formData,
            userId: userComId
        });
        return this.userComService.subscribe(formData);
    }
};
__decorate([
    Inject(UserComServiceType)
], NewsletterModuleUI.prototype, "userComService", void 0);
NewsletterModuleUI = __decorate([
    Component({
        name: 'NewsletterModuleUI'
        // FIXME: Create widget for user.com to
        // mounted (): void {
        //   this.userComService.initializeUserComScript()
        // }
    })
], NewsletterModuleUI);
export { NewsletterModuleUI };
