// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
import { TransactionStatus } from '../contracts/models';
import { Transaction } from './transaction';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Subscription extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'endedAt', 'id', 'isPaid', 'period', 'price', 'promotion', 'renewable', 'status', 'startedAt', 'transaction', 'type'
        ];
    }
    isPending() {
        return this._data.transaction && this._data.transaction.status === TransactionStatus.Pending;
    }
    getActiveTransaction() {
        return this._data.transaction ? this._data.transaction : null;
    }
    relatesToTransaction(value) {
        return this.hasOne(Transaction, value);
    }
}
