// Copyright © 2021 Move Closer

// const path = require('path')
const _path = require('path')
const path = _path.posix ? _path.posix : _path
const __dirname = '/'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export default {
  plugins: [
    '@uvue/core/plugins/vuex',
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'async-data.ts'),
    '@uvue/core/plugins/prefetch',
    '@uvue/core/plugins/middlewares',
    '@uvue/core/plugins/errorHandler',
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'init-setup.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'error-clear.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'progress-bar.ts')
  ],
  css: {
    normal: process.env.VUE_APP_ENV === 'local' ? 'inline' : 'extract',
    vue: process.env.VUE_APP_ENV === 'local' ? 'inline' : 'extract'
  }
}
