// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
import { SubscriptionPeriod } from '@subscription/contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class User extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'avatar',
            'email',
            'uniqueId',
            'firstName',
            'id',
            'isActive',
            'lastName',
            'nickname',
            'nicknameConfirmed',
            'prevSubscription',
            'subscription',
            'subscriptionType',
            'subscriptionPeriod',
            'userComId',
            'companyData'
        ];
    }
    get fullName() {
        return `${this._data.firstName} ${this._data.lastName}`;
    }
    isPaidPremium() {
        return !!this._data.subscriptionPeriod && [SubscriptionPeriod.Annual, SubscriptionPeriod.Monthly].includes(this._data.subscriptionPeriod);
    }
    isPremium() {
        return !!this._data.subscriptionPeriod;
    }
}
