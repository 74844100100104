// Copyright © 2021 Move Closer
import { ModuleDriver, moduleDriverUiComponentRegistry } from '@d24/modules/front';
import { Hydration } from '@component/PageBuilder';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { ContactModule } from '@content/shared/components/Contacts/Contacts';
import { LiveFeed } from '@content/shared/builder/LiveFeed/LiveFeed.vue';
import { NewsletterModuleUI } from '@content/shared/components/Newsletter/Newsletter';
import { RequestAccessModuleUI } from '@/modules/forms/shared/RequestAccess';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
const componentsHydration = {
    [ModuleDriver.Heading]: Hydration.Never,
    [ModuleDriver.HR]: Hydration.Never,
    [ModuleDriver.HTML]: Hydration.WhenVisible,
    [ModuleDriver.Paragraph]: Hydration.OnInteraction,
    [ModuleDriver.Quotes]: Hydration.OnInteraction
};
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
const componentsRegistry = {
    ...moduleDriverUiComponentRegistry,
    [ModuleDriver.Contact]: ContactModule,
    [ModuleDriver.LiveFeed]: LiveFeed,
    [ModuleDriver.Newsletter]: NewsletterModuleUI,
    [ModuleDriver.RequestAccess]: RequestAccessModuleUI
};
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const pageBuilder = {
    components: componentsRegistry,
    defaultHydration: Hydration.WhenVisible,
    hydration: componentsHydration
};
