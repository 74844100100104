// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Collection, Injectable, mapCollection, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { Product } from '../models/product';
import { productAdapterMap } from '../models/product.adapter';
import { Subscription } from '../models/subscription';
import { subscriptionAdapterMap } from '../models/subscription.adapter';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let SubscriptionsRepository = class SubscriptionsRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = subscriptionAdapterMap;
        this.useAdapter = true;
    }
    async cancel(id) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'cancel', { id });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
    }
    async restore(id) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'restore', { id });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
    }
    async create(payload) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'create', {}, payload);
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return this.composeModel(response.data.data, Subscription);
    }
    async load(id) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'load', { id });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        // FIXME: There's no `data` wrapper key :(
        return this.composeModel(response.data.data, Subscription);
    }
    async pricing() {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'pricing');
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return new Collection(mapCollection(response.data.data, productAdapterMap)
            .map((item) => Product.hydrate(item)));
    }
};
SubscriptionsRepository = __decorate([
    Injectable()
], SubscriptionsRepository);
export { SubscriptionsRepository };
