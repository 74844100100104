// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
export class Card extends Model {
    constructor() {
        super(...arguments);
        this.cardTypes = [
            {
                name: 'Mastercard',
                regex: /^5[1-5]/
            },
            {
                name: 'VISA',
                regex: /^4/
            }
        ];
    }
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'expirationMonth',
            'expirationYear',
            'expireAt',
            'id',
            'isActive',
            'number'
        ];
    }
    getType() {
        var _a;
        if (!this._data.number) {
            return '';
        }
        const controlNumber = this._data.number.slice(0, 3);
        const cardType = this.cardTypes.find(c => c.regex.test(controlNumber));
        return (_a = cardType === null || cardType === void 0 ? void 0 : cardType.name) !== null && _a !== void 0 ? _a : controlNumber;
    }
    getLabel() {
        if (!this._data.number) {
            return '';
        }
        return `${this.getType()} •••• ${this._data.number.slice(-4)}`;
    }
}
