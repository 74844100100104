// Copyright © 2021 Move Closer
/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export var GrantType;
(function (GrantType) {
    GrantType["Credentials"] = "credentials";
    GrantType["Google"] = "google";
    GrantType["Facebook"] = "facebook";
    GrantType["Twitter"] = "twitter";
    GrantType["LinkedIn"] = "linkedin";
})(GrantType || (GrantType = {}));
