// Copyright © 2021 Move Closer
import { isDevelopmentEnv } from '@support/environment';
/**
 * This function mute `debug` & `log` level of console which are not useful
 * while debugging ssr output.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function limitSsrConsole() {
    if (typeof window !== 'undefined') {
        return;
    }
    if (isDevelopmentEnv()) {
        return;
    }
    console.debug = function () {
        // Nothing to do.
    };
    console.log = function () {
        // Nothing to do.
    };
}
