// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
import { EShareService } from './share.contracts';
import { isEmailSharePayload, isFacebookSharePayload, isLinkedinSharePayload, isShareServiceConfig, isTwitterSharePayload } from './share.guards';
import { openPopUp } from './share.helpers';
/**
 * Service that allows to easily share various content using multiple share providers.
 *
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let ShareService = class ShareService {
    constructor(config, modalConnectorService, windowService, siteService) {
        this.modalConnector = modalConnectorService;
        this.windowService = windowService;
        this.siteService = siteService;
        if (!isShareServiceConfig(config)) {
            throw new Error(`Provided config is NOT valid!\n${config}`);
        }
        this.config = config;
    }
    share(service, payload) {
        var _a;
        let emailPayload;
        const meta = payload.meta;
        switch (service) {
            case EShareService.Email:
                emailPayload = {
                    subject: encodeURIComponent((_a = meta.title) !== null && _a !== void 0 ? _a : ''),
                    body: encodeURIComponent(`${meta.description}

Przeczytaj więcej pod tym linkiem:

${this.addUTMParams(payload.url, 'email')}
`)
                };
                if (!isEmailSharePayload(emailPayload)) {
                    throw new Error('[ShareService] Passed-in payload is NOT a valid EmailSharePayload');
                }
                this.emailShare(emailPayload);
                break;
            case EShareService.Facebook:
                if (!isFacebookSharePayload(payload)) {
                    throw new Error('[ShareService] Passed-in payload is NOT a valid FacebookSharePayload');
                }
                this.facebookShare(payload);
                break;
            case EShareService.Instagram:
                this.instagramShare();
                break;
            case EShareService.Linkedin:
                if (!isLinkedinSharePayload(payload)) {
                    throw new Error('[ShareService] Passed-in payload is NOT a valid LinkedinSharePayload');
                }
                this.linkedinShare(payload);
                break;
            case EShareService.Twitter:
                if (!isTwitterSharePayload(payload)) {
                    throw new Error('[ShareService] Passed-in payload is NOT a valid TwitterSharePayload');
                }
                this.twitterShare(payload);
                break;
            case EShareService.CopyLink:
                navigator.clipboard.writeText(this.addUTMParams(payload.url, 'organic'))
                    .catch(e => {
                    console.error(e);
                });
                break;
        }
    }
    /**
     * Shares the content using the User's default e-mail client.
     */
    emailShare(payload) {
        window.location.assign(`mailto:?subject=${payload.subject}&body=${payload.body}`);
    }
    /**
     * Shares the content using the Facebook share dialog.
     *
     * @see https://developers.facebook.com/docs/sharing/reference/share-dialog
     */
    facebookShare(payload) {
        const url = `https://www.facebook.com/dialog/share?app_id=${this.config.facebook.appId}&href=${payload.url || ''}&quote=${payload.quote || ''}&hashtag=${payload.hashtag || ''}`;
        const config = {
            width: '750',
            height: '550'
        };
        openPopUp(url, config, this.windowService);
    }
    /**
     * Shares the content using the instagram share.
     */
    instagramShare() {
        const url = 'https://www.instagram.com/defence_24.pl/';
        const config = {
            width: '750',
            height: '550'
        };
        openPopUp(url, config, this.windowService);
    }
    /**
     * Shares the content using the Twitter share dialog.
     *
     * @see https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/parameter-reference1
     */
    twitterShare(payload) {
        const allowedParams = ['text', 'url', 'hashtags', 'via', 'related'];
        const urlParams = allowedParams.reduce((acc, curr) => {
            if (typeof payload[curr] === 'string') {
                return [...acc, {
                        param: curr,
                        value: payload[curr]
                    }];
            }
            return acc;
        }, []);
        const url = `https://twitter.com/share${urlParams.reduce((acc, curr, index) => {
            return `${acc}${index === 0 ? '?' : '&'}${curr.param}=${curr.value}`;
        }, '')}`;
        const config = {
            width: '750',
            height: '550'
        };
        openPopUp(url, config, this.windowService);
    }
    /**
     * Shares the content using the Linked in share dialog.
     */
    linkedinShare(payload) {
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${payload.url}`;
        const config = {
            width: '750',
            height: '550'
        };
        openPopUp(url, config, this.windowService);
    }
    addUTMParams(url, medium) {
        return `${url}?utm_source=${this.siteService.getActiveSite().domain}&utm_medium=${medium}&utm_content=belka`;
    }
};
ShareService = __decorate([
    Injectable()
], ShareService);
export { ShareService };
