// Copyright © 2021 Move Closer
import { MappingTypes } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const productAdapterMap = {
    bestOffer: 'bestOffer',
    customParams: {
        type: MappingTypes.Function,
        value: item => (Array.isArray(item.customParams) ? {} : item.customParams)
    },
    fullPrice: 'fullPrice',
    period: 'period',
    price: 'price',
    promo: {
        type: MappingTypes.Function,
        value: () => false
    },
    renewable: 'renewal',
    saves: 'saves',
    type: 'type'
};
