// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { LiveFeed } from '@content/models/live-feed';
import { liveFeedAdapterMap } from '@content/models/live-feed.adapter';
/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
let LiveFeedsRepository = class LiveFeedsRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = liveFeedAdapterMap;
        this.useAdapter = true;
    }
    async fetch(id) {
        var _a;
        const response = await this.connector.call('feeds', 'fetch', { id });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response));
        }
        return this.composeModel(response.data.data, LiveFeed);
    }
};
LiveFeedsRepository = __decorate([
    Injectable()
], LiveFeedsRepository);
export { LiveFeedsRepository };
