// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const userAdapterMap = {
    avatar: 'avatar',
    email: 'email',
    uniqueId: 'uniqueId',
    firstName: 'firstName',
    lastName: 'lastName',
    id: 'id',
    nickname: 'nickname',
    nicknameConfirmed: 'nicknameConfirmed',
    prevSubscription: 'previousPeriodUuid'
};
