// Copyright © 2022 Move Closer
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { getContext, sanitizeComponent } from '@uvue/core/lib';
const noopData = () => ({});
/**
 * Method to inject asyncData results to $data on component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const applyAsyncData = (Component, asyncData) => {
    if (typeof Component.options.initData === 'undefined') {
        Component.options.initData = Component.options.data || noopData;
    }
    if (!asyncData && Component.options.hasAsyncData) {
        return;
    }
    Component.options.hasAsyncData = true;
    Component.options.data = function () {
        const data = Component.options.initData.call(this);
        return { ...data, ...(asyncData || {}) };
    };
    if (Component._Ctor && Component._Ctor.options) {
        Component._Ctor.options.data = Component.options.data;
    }
};
/**
 * Get components with asyncData defined
 */
const getAsyncDataComponents = (components = []) => {
    return components
        .map(component => {
        return sanitizeComponent(component);
    })
        .filter(Component => Component && Component.options.asyncData);
};
/**
 * This will take all pages components in current route and
 * call and apply asyncData on them
 */
const resolveComponentsAsyncData = (context, route, components) => {
    const { router } = context;
    if (!route)
        route = router.currentRoute;
    if (!components)
        components = router.getMatchedComponents(route);
    return Promise.all(getAsyncDataComponents(components).map(async (Component) => {
        if (!Component)
            return;
        const data = await Component.options.asyncData(context);
        if (data)
            applyAsyncData(Component, data);
        return data;
    }));
};
/**
 * Get page components in a tree of components
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findAsyncDataComponents = (parent, components = []) => {
    for (const child of parent.$children) {
        if (child.$vnode && child.$vnode.data.routerView) {
            components.push(child);
        }
        if (child.$children.length) {
            findAsyncDataComponents(child, components);
        }
    }
    return components;
};
/**
 * Attach HMR behavior on components to re-apply asyncData
 */
const addHotReload = (context) => {
    if (!module.hot || !context.isClient)
        return;
    const { app, router } = context;
    const components = findAsyncDataComponents(app);
    for (const depth in components) {
        const component = components[depth];
        const _forceUpdate = component.$forceUpdate.bind(component.$parent);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        component.$vnode.context.$forceUpdate = async () => {
            const routeComponents = router.getMatchedComponents(router.currentRoute);
            const Component = sanitizeComponent(routeComponents[depth]);
            try {
                if (Component && Component.options.asyncData) {
                    const data = await Component.options.asyncData(getContext(context));
                    applyAsyncData(Component, data);
                }
            }
            catch (err) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                component.$error(err);
            }
            return _forceUpdate();
        };
    }
};
/**
 * AsyncData plugin.
 */
const AsyncDataPlugin = {
    /**
     * Attach HMR behavior on route changes
     */
    beforeStart(context) {
        const { app, router } = context;
        router.afterEach(() => {
            if (!app) {
                return;
            }
            app.$nextTick(() => {
                addHotReload(context);
            });
        });
    },
    /**
     * On each route resolve call asyncData on components
     */
    async routeResolve(context) {
        const { ssr, route, routeComponents } = context;
        const components = await resolveComponentsAsyncData(context, route, routeComponents);
        if (context.isServer && ssr) {
            ssr.data.components = components;
        }
    },
    async beforeReady({ router, isClient }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (isClient && window.__DATA__ && window.__DATA__.components) {
            const asyncDataComponents = getAsyncDataComponents(router.getMatchedComponents(router.currentRoute));
            for (const index in asyncDataComponents) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                if (window.__DATA__.components[index]) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    applyAsyncData(asyncDataComponents[index], window.__DATA__.components[index]);
                }
            }
        }
    },
    /**
     * Attach HMR behavior on route ready
     */
    ready(context) {
        addHotReload(getContext(context));
    }
};
export default AsyncDataPlugin;
