import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SiteLogoVariant, SiteServiceType } from '@service/site';
import { Inject } from '@plugin/inversify';
import { ModalType } from '@movecloser/front-core';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let FooterBottom = class FooterBottom extends Vue {
    /**
     * Alternative text for the logotype image.
     */
    get logotypeAlt() {
        return `${this.$t('components.navbar.logotype-of-the-xyz-site')} ${this.site.name}`;
    }
    /**
     * Logotype SVG file to render.
     */
    get logotype() {
        const logoSize = this.site.properties.logoSize;
        return {
            src: this.site.logo[SiteLogoVariant.Dark] || '',
            width: logoSize === null || logoSize === void 0 ? void 0 : logoSize.width,
            height: logoSize === null || logoSize === void 0 ? void 0 : logoSize.height
        };
    }
    /**
     * Handles the `@click` event on the "search" button.
     * Toggle is searching value
     */
    showConsentPopup() {
        window.googlefc = window.googlefc || {};
        window.googlefc.showRevocationMessage();
    }
    /**
     * Return current Site.
     */
    get site() {
        return this.siteService.getActiveSite();
    }
};
__decorate([
    Prop({ type: Object, required: false })
], FooterBottom.prototype, "mainLink", void 0);
__decorate([
    Prop({ type: Array, required: false })
], FooterBottom.prototype, "smallLinks", void 0);
__decorate([
    Inject(ModalType)
], FooterBottom.prototype, "modalConnector", void 0);
__decorate([
    Inject(SiteServiceType)
], FooterBottom.prototype, "siteService", void 0);
FooterBottom = __decorate([
    Component({ name: 'FooterBottom' })
], FooterBottom);
export { FooterBottom };
export default FooterBottom;
