// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export var EModal;
(function (EModal) {
    EModal["AccountNotFound"] = "accountNotFound";
    EModal["ActivationLinkSent"] = "activationLinkSent";
    EModal["BuySubscription"] = "buySubscription";
    EModal["BuySingleAccess"] = "buySingleAccess";
    EModal["BuySingleAccessSteps"] = "buySingleAccessSteps";
    EModal["ForgotPassword"] = "forgotPassword";
    EModal["FullNameSetup"] = "fullNameSetup";
    EModal["Info"] = "info";
    EModal["LogIn"] = "logIn";
    EModal["LogInError"] = "logInError";
    EModal["MessageSent"] = "messageSent";
    EModal["NoNicknameSet"] = "noNicknameSet";
    EModal["NotActiveAccountModal"] = "notActiveAccountModal";
    EModal["NoAccountModal"] = "noAccountModal";
    EModal["PaymentError"] = "paymentError";
    EModal["SetNickname"] = "setNickname";
    EModal["SubscriptionConflict"] = "subscriptionConflict";
    EModal["Welcome"] = "welcome";
})(EModal || (EModal = {}));
