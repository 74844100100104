// Copyright © 2021 Move Closer
export var PromotionProductType;
(function (PromotionProductType) {
    PromotionProductType["Article"] = "article";
    PromotionProductType["Subscription"] = "subscription";
})(PromotionProductType || (PromotionProductType = {}));
export var SubscriptionsStatus;
(function (SubscriptionsStatus) {
    SubscriptionsStatus["NotStarted"] = "not_started";
    SubscriptionsStatus["Expired"] = "expired";
    SubscriptionsStatus["DaysLeft"] = "days_left";
    SubscriptionsStatus["Paid"] = "paid";
})(SubscriptionsStatus || (SubscriptionsStatus = {}));
export var SubscriptionPeriod;
(function (SubscriptionPeriod) {
    SubscriptionPeriod["None"] = "none";
    SubscriptionPeriod["Annual"] = "annual";
    SubscriptionPeriod["Monthly"] = "monthly";
    SubscriptionPeriod["SemiAnnual"] = "semi-annual";
})(SubscriptionPeriod || (SubscriptionPeriod = {}));
export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["Premium"] = "premium";
    SubscriptionType["Standard"] = "standard";
})(SubscriptionType || (SubscriptionType = {}));
export var TransactionDriver;
(function (TransactionDriver) {
    TransactionDriver["PayU"] = "payu";
    TransactionDriver["PayURecursive"] = "payu-recursive";
})(TransactionDriver || (TransactionDriver = {}));
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["Fail"] = "fail";
    TransactionStatus["New"] = "new";
    TransactionStatus["Pending"] = "pending";
    TransactionStatus["Success"] = "success";
})(TransactionStatus || (TransactionStatus = {}));
