// Copyright © 2023 Move Closer
import { AbstractIntention } from '@movecloser/front-core';
/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export class RegisterIntention extends AbstractIntention {
    constructor() {
        super(...arguments);
        this.map = {
            code: 'code',
            email: 'email',
            grantType: 'grantType',
            token: 'token',
            password: 'password',
            remember: 'remember',
            passwordConfirmation: 'passwordConfirmation',
            extra: 'extra'
        };
    }
}
