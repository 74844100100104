// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export class Navigation extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'links',
            'name',
            'slot'
        ];
    }
}
