// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { log } from '@support/log';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let CapturesError = class CapturesError extends Vue {
    constructor() {
        super(...arguments);
        /**
         * By default this component captures all errors to prevent render issue.
         * @protected
         */
        this.capture = true;
    }
    /**
     * By this method we can decorate error with extra information.
     * @param error
     * @protected
     */
    decorateError(error) {
        return error;
    }
};
CapturesError = __decorate([
    Component({
        errorCaptured(error) {
            log(this.decorateError(error), 'error');
            return !this.capture;
        }
    })
], CapturesError);
export { CapturesError };
