// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class SneakPeak extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'author', 'contentId', 'parent', 'properties', 'publicationDate', 'site', 'title', 'type', 'urlPath'
        ];
    }
    /**
     * Return Addon properties.
     */
    getProperty(addon) {
        if (!this.hasProperty(addon)) {
            return {};
        }
        return this._data.properties[addon];
    }
    /**
     * Check if model has given Addon set.
     */
    hasProperty(addon) {
        return typeof this._data.properties[addon] !== 'undefined' && this._data.properties[addon] !== null;
    }
}
