// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { AbstractAsyncRelatedService } from '@d24/modules/front';
import { Injectable } from '@movecloser/front-core';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let RelatedService = class RelatedService extends AbstractAsyncRelatedService {
    getRelated() {
        return this.record;
    }
};
RelatedService = __decorate([
    Injectable()
], RelatedService);
export { RelatedService };
