// Copyright © 2022 Move Closer
import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { RequestAccessModuleUi } from '@d24/modules/front';
import { Inject } from '@plugin/inversify';
import { UserComServiceType } from '@service/usercom';
import { FormRepositoryType } from '@/modules/forms/contracts';
let RequestAccessModuleUI = class RequestAccessModuleUI extends RequestAccessModuleUi {
    async onSubmit(uuid, formData, eventName) {
        try {
            await this.formRepository.send(uuid, formData);
        }
        catch (e) {
            console.warn(e);
        }
        try {
            await this.userComService.sendEvent(eventName, formData);
        }
        catch (e) {
            console.warn(e);
        }
    }
};
__decorate([
    Inject(FormRepositoryType)
], RequestAccessModuleUI.prototype, "formRepository", void 0);
__decorate([
    Inject(UserComServiceType)
], RequestAccessModuleUI.prototype, "userComService", void 0);
RequestAccessModuleUI = __decorate([
    Component({
        name: 'RequestAccessModuleUI'
        // FIXME: Create widget for user.com to
        // mounted (): void {
        //  this.userComService.initializeUserComScript()
        // }
    })
], RequestAccessModuleUI);
export { RequestAccessModuleUI };
