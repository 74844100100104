// Copyright © 2022 Move Closer
import { __decorate } from "tslib";
import { ApiConnectorType, AppModule, Module } from '@movecloser/front-core';
import { FormRepositoryType } from './contracts';
import { FormRepository } from './repositories/forms';
import { resources } from './resources';
let FormModule = class FormModule extends Module {
};
FormModule = __decorate([
    AppModule({
        name: 'forms',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        providers: (config) => {
            return (bind) => {
                bind(FormRepositoryType).to(FormRepository);
            };
        },
        boot: (container) => {
            const connector = container.get(ApiConnectorType);
            connector.useResources(resources);
        }
    })
], FormModule);
export { FormModule };
