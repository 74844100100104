// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
/* eslint-disable @typescript-eslint/camelcase */
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { Token } from '../models/token';
import { tokenAdapterMap } from '../models/token.adapter';
/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
let AuthRepository = class AuthRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = tokenAdapterMap;
        this.useAdapter = true;
    }
    async activate(token) {
        var _a;
        const response = await this.connector.call('auth', 'activateAccount', {}, { token });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
    }
    async changePassword(data) {
        var _a;
        const response = await this.connector.call('me', 'changePassword', {}, { actual: data.currentPassword, password: data.newPassword, password_confirmation: data.newPasswordConfirmation });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response));
        }
    }
    async forgotPassword(email) {
        var _a;
        const response = await this.connector.call('auth', 'forgotPassword', {}, { email });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
    }
    async logIn(intention) {
        var _a, _b;
        const response = await this.connector.call('auth', 'logIn', {}, intention.toRequest());
        if (!response.isSuccessful() && response.status === 403) {
            throw new ResourceActionFailed(response.data.reasons, resolveFromStatus(response), response.data);
        }
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return this.composeModel(response.data.data, Token);
    }
    async logInWithSocial(provider) {
        var _a, _b;
        const response = await this.connector.call('auth', 'logInWithSocial', {}, { grantType: provider });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return Promise.resolve(response.data.externalLoginUrl);
    }
    async logOut() {
        var _a;
        const response = await this.connector.call('auth', 'logOut');
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
    }
    async refresh(token) {
        var _a;
        const response = await this.connector.call('auth', 'refreshToken', {}, { token });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
        return this.composeModel(response.data.data, Token);
    }
    async register(intention) {
        var _a;
        const response = await this.connector.call('auth', 'createAccount', {}, intention.toRequest());
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), response.data.errors);
        }
        return this.composeModel(response.data.data, Token);
    }
    async resetPassword(body) {
        var _a, _b;
        const response = await this.connector.call('auth', 'resetPassword', {}, { ...body, password_confirmation: body.passwordConfirmation });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), { ...(_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors });
        }
    }
};
AuthRepository = __decorate([
    Injectable()
], AuthRepository);
export { AuthRepository };
