import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { ContactModuleUi } from '@d24/modules/front';
import { Inject } from '@plugin/inversify';
import { UserComServiceType } from '@service/usercom';
let ContactModule = class ContactModule extends ContactModuleUi {
    onSubmit(formData) {
        formData.payload = {};
        formData.payload.fullMessage = formData.message || '';
        if (typeof formData.message === 'string' && formData.message.length > 50) {
            formData.message = `${formData.message.substr(0, 50)}...`;
        }
        return this.userComService.sendContactForm(formData);
    }
};
__decorate([
    Inject(UserComServiceType)
], ContactModule.prototype, "userComService", void 0);
ContactModule = __decorate([
    Component({
        name: 'ContactModule'
        // FIXME: Create widget for user.com to
        // mounted (): void {
        //   this.userComService.initializeUserComScript()
        // }
    })
], ContactModule);
export { ContactModule };
