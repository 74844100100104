// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const routesFactory = () => {
    return [
        {
            path: '/moje-konto',
            component: () => import(/* webpackChunkName: "MyAccountView" */ './views/MyAccountView/MyAccountView.vue'),
            redirect: '/moje-konto/moje-dane',
            children: [
                {
                    path: 'moje-dane',
                    name: 'my-data',
                    component: () => import(/* webpackChunkName: "MyDataView" */ './views/MyAccountView/MyDataView/MyDataView.vue')
                },
                {
                    path: 'moje-dane/zmien-zdjecie',
                    name: 'change-avatar',
                    component: () => import(/* webpackChunkName: "ChangeAvatarView" */ './views/MyAccountView/MyDataView/ChangeAvatarView/ChangeAvatarView.vue')
                },
                {
                    path: 'moje-dane/zmien-pseudonim',
                    name: 'change-nickname',
                    component: () => import(/* webpackChunkName: "ChangeNicknameView" */ './views/MyAccountView/MyDataView/ChangeNicknameView/ChangeNicknameView.vue')
                },
                {
                    path: 'moje-dane/zmien-haslo',
                    name: 'change-password',
                    component: () => import(/* webpackChunkName: "ChangePasswordView" */ './views/MyAccountView/MyDataView/ChangePasswordView/ChangePasswordView.vue')
                },
                {
                    path: 'moje-dane/zmien-dane-do-faktury',
                    name: 'change-company-data',
                    component: () => import(/* webpackChunkName: "ChangeCompanyDataView" */ './views/MyAccountView/MyDataView/ChangeCompanyDataView/ChangeCompanyDataView.vue')
                },
                {
                    path: 'moje-dane/zarzadzaj-kartami',
                    name: 'manage-cards',
                    component: () => import(/* webpackChunkName: "ManageCardsView" */ './views/MyAccountView/MyDataView/ManageCardsView/ManageCardsView.vue')
                },
                // {
                //   path: 'newsletter',
                //   name: 'newsletter',
                //   component: () => import(/* webpackChunkName: "NewsletterView" */
                //     './views/MyAccountView/NewsletterView/NewsletterView.vue'
                //   )
                // },
                {
                    path: 'subskrypcja',
                    name: 'subscription',
                    component: () => import(/* webpackChunkName: "SubscriptionView" */ './views/MyAccountView/SubscriptionView/SubscriptionView.vue')
                },
                {
                    path: 'artykuly',
                    name: 'articles',
                    component: () => import(/* webpackChunkName: "ArticlesView" */ './views/MyAccountView/ArticlesView/ArticlesView.vue')
                }
            ]
        },
        {
            path: '/zresetuj-haslo/:token',
            name: 'password-reset',
            component: () => import(/* webpackChunkName: "PasswordResetView" */ './views/PasswordResetView/PasswordResetView.vue')
        },
        {
            path: '/aktywuj-konto/:token',
            name: 'activate',
            component: () => import(/* webpackChunkName: "ActivateAccountView" */ './views/ActivateAccountView/ActivateAccountView.vue')
        },
        {
            path: '/oauth',
            name: 'oauth',
            component: () => import(/* webpackChunkName: "OAuth" */ './views/OAuth/OAuth.vue')
        }
    ];
};
