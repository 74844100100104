// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { ApiConnectorType, AppModule, Module } from '@movecloser/front-core';
import { PromotionsRepositoryType, SubscriptionsRepositoryType, TransactionsRepositoryType } from './contracts/repositories';
import { resources } from './resources';
import { routesFactory as routes } from './routes';
import { SubscriptionsRepository } from './repositories/subscriptions';
import { TransactionsRepository } from './repositories/transactions';
import { PromotionsRepository } from '@subscription/repositories/promotions';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let SubscriptionModule = class SubscriptionModule extends Module {
};
SubscriptionModule = __decorate([
    AppModule({
        name: 'subscription',
        routes,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        providers: (config) => {
            return (bind) => {
                bind(PromotionsRepositoryType).to(PromotionsRepository);
                bind(SubscriptionsRepositoryType).to(SubscriptionsRepository);
                bind(TransactionsRepositoryType).to(TransactionsRepository);
            };
        },
        boot: (container) => {
            const connector = container.get(ApiConnectorType);
            connector.useResources(resources);
        }
    })
], SubscriptionModule);
export { SubscriptionModule };
