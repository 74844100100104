// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationServiceType, NavigationSlot } from '@service/navigation';
import { Inject } from '@plugin/inversify';
import { ErrorDetails } from '@component/ErrorDetails/ErrorDetails.vue';
import { Footer } from '@component/Footer/Footer.vue';
import { Layout } from '@component/Layout/Layout.vue';
import { Navbar } from '@component/Navbar/Navbar.vue';
import { SearchBar } from '@component/SearchBar/SearchBar.vue';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let DefaultLayout = class DefaultLayout extends Vue {
    constructor() {
        super(...arguments);
        this.isSearching = false;
    }
    get footerBottomNav() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.FooterBottom)
            ? ((_a = this.navigation.getSlot(NavigationSlot.FooterBottom)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get footerTopNav() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.FooterTop)
            ? ((_a = this.navigation.getSlot(NavigationSlot.FooterTop)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get hasErrors() {
        return this.$errorHandler.error !== null;
    }
    get routeName() {
        if (!this.$route.name) {
            return '';
        }
        return this.$route.name;
    }
    /**
     * Determines whether `Subscribe` button should be visible or not
     */
    get shouldRenderSubscriptionBtn() {
        return this.navigation.getSubscriptionButtonState();
    }
    get premiumButtonLink() {
        return this.navigation.getPremiumButtonLink() || '';
    }
    get sideNav() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.Left)
            ? ((_a = this.navigation.getSlot(NavigationSlot.Left)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get socialNav() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.Social)
            ? ((_a = this.navigation.getSlot(NavigationSlot.Social)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get topNav() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.Top)
            ? ((_a = this.navigation.getSlot(NavigationSlot.Top)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get queryParams() {
        return this.$route.query;
    }
    /**
     * Determine if Layout uses Sidebar.
     */
    static hasSidebar() {
        return true;
    }
    setAdTargeting(targeting) {
        this.$emit('setAdTargeting', targeting);
    }
    /**
     * Toggle isSearching value
     */
    toggleSearching() {
        if (Object.prototype.hasOwnProperty.call(this.queryParams, 'q')) {
            this.$router.push('/');
        }
        this.$nextTick(() => {
            this.isSearching = !this.isSearching;
        });
    }
    onRouteNameChange(name, prevName) {
        if (name === 'content.search') {
            this.isSearching = true;
        }
        else if (prevName === 'content.search' && name !== prevName) {
            this.isSearching = false;
        }
    }
};
__decorate([
    Inject(NavigationServiceType)
], DefaultLayout.prototype, "navigation", void 0);
__decorate([
    Watch('routeName', { deep: true })
], DefaultLayout.prototype, "onRouteNameChange", null);
DefaultLayout = __decorate([
    Component({
        name: 'DefaultLayout',
        components: {
            ErrorDetails,
            Footer,
            Layout,
            Navbar,
            SearchBar
        },
        created() {
            if (this.$route.name === 'content.search') {
                this.isSearching = true;
            }
        },
        template: `
    <Layout>
      <template slot="top">
        <Navbar :links="topNav" :isSearching="isSearching" :showSubscriptionBtn="shouldRenderSubscriptionBtn"
                :premiumButtonLink="premiumButtonLink" @toggleSearching="toggleSearching" />
        <SearchBar v-if="isSearching" />
      </template>

      <slot>
        <template slot="middle">
          <ErrorDetails v-if="hasErrors" />
          <router-view v-else :key="$route.fullPath" @setAdTargeting="setAdTargeting" />
        </template>
      </slot>

      <Footer slot="bottom" :topLinks="footerTopNav" :bottomLinks="footerBottomNav" />
    </Layout>
  `
    })
], DefaultLayout);
export { DefaultLayout };
