// Copyright © 2021 Move Closer
import { Methods } from '@movecloser/front-core';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const resources = {
    navigations: {
        connection: 'd24',
        prefix: '',
        methods: {
            list: {
                url: 'navigations/front',
                method: Methods.Get
            }
        }
    }
};
