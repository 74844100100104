// Copyright © 2021 Move Closer
export const storeFactory = () => ({
    namespaced: true,
    state: () => ({
        supportedFormats: null
    }),
    mutations: {
        addSupportedFormat(state, formats) {
            state.supportedFormats = formats;
        },
        clearSupportedFormats(state) {
            state.supportedFormats = '';
        }
    },
    getters: {
        hasSupportedFormats: (state) => state.supportedFormats !== null,
        supportedFormats: (state) => { var _a; return (_a = state.supportedFormats) !== null && _a !== void 0 ? _a : ''; }
    }
});
