// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const storeFactory = () => ({
    namespaced: true,
    state: () => ({
        isUserLoggedIn: false,
        isWaitingForAuth: true,
        me: null,
        subscription: null
    }),
    actions: {
        setIsUserLoggedIn({ commit }, isUserLoggedIn) {
            commit('SET_IS_USER_LOGGED_IN', isUserLoggedIn);
        },
        setIsWaitingForAuth({ commit }, isWaitingForAuth) {
            commit('SET_IS_WAITING_FOR_AUTH', isWaitingForAuth);
        },
        setMe({ commit }, me) {
            commit('SET_ME', me);
        },
        setSubscription({ commit }, subscription) {
            commit('SET_SUBSCRIPTION', subscription);
        }
    },
    mutations: {
        SET_IS_USER_LOGGED_IN(state, isUserLoggedIn) {
            state.isUserLoggedIn = isUserLoggedIn;
        },
        SET_IS_WAITING_FOR_AUTH(state, isWaitingForAuth) {
            state.isWaitingForAuth = isWaitingForAuth;
        },
        SET_ME(state, me) {
            state.me = me;
        },
        SET_ME_DETAILS(state, details) {
            if (!state.me) {
                return;
            }
            const me = state.me;
            for (const [key, value] of Object.entries(details)) {
                me.set(key, value);
            }
            state.me = me;
        },
        SET_SUBSCRIPTION(state, sub) {
            state.subscription = sub;
        }
    },
    getters: {
        isWaitingForAuth: state => state.isWaitingForAuth,
        isUserLoggedIn: state => state.isUserLoggedIn,
        me: state => state.me,
        subscription: state => state.subscription
    }
});
