import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { EventbusType } from '@movecloser/front-core';
import { Inject } from '@plugin/inversify';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
let Layout = class Layout extends Vue {
    constructor() {
        super(...arguments);
        /**
         * Determines whether the middle section should be dimmed.
         */
        this.isMiddleDimmed = false;
    }
    /**
     * Handles the `@click` event on the `.layout__middle__overlay`.
     */
    onOverlayClick() {
        if (this.isMiddleDimmed)
            this.closeSidebar();
    }
    /**
     * Attaches all the needed event listeners.
     */
    attachListeners() {
        this.eventBus.handle('ui:layout.dim', this.dimMiddle);
        this.eventBus.handle('ui:layout.lit', this.litMiddle);
    }
    /**
     * Dims the middle section.
     */
    dimMiddle() {
        this.isMiddleDimmed = true;
    }
    /**
     * Closes the sidebar.
     */
    closeSidebar() {
        this.eventBus.emit('ui:sidebar.close');
    }
    /**
     * Lits the middle section up.
     */
    litMiddle() {
        this.isMiddleDimmed = false;
    }
};
__decorate([
    Inject(EventbusType)
], Layout.prototype, "eventBus", void 0);
Layout = __decorate([
    Component({
        name: 'Layout',
        mounted() {
            this.attachListeners();
        }
    })
], Layout);
export { Layout };
export default Layout;
