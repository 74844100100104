// Copyright © 2021 Move Closer
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
/**
 * Creates the Vuex instance with all add-ons.
 *
 * @param modules - Store configuration from the `@AppModule`s.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const createStore = (modules) => {
    return new Vuex.Store({
        modules,
        state: () => ({
            isAuthRequiredToProcess: false,
            isMaintenanceMode: false,
            routeHistory: [],
            siteMetaTitle: null
        }),
        actions: {
            pushRouteToHistory({ commit, state }, route) {
                commit('SET_ROUTE_HISTORY', [...state.routeHistory, route]);
            }
        },
        mutations: {
            SET_ROUTE_HISTORY(state, routeHistory) {
                state.routeHistory = routeHistory;
            },
            SET_SITE_META_TITLE(state, siteMetaTitle) {
                state.siteMetaTitle = siteMetaTitle;
            },
            toggleAuthRequirement(state, isAuthRequired) {
                state.isAuthRequiredToProcess = isAuthRequired;
            },
            toggleMaintenanceMode(state, isMaintenanceOn) {
                state.isMaintenanceMode = isMaintenanceOn;
            }
        },
        getters: {
            isAuthRequired(state) {
                return state.isAuthRequiredToProcess;
            },
            isMaintenance(state) {
                return state.isMaintenanceMode;
            },
            routeHistory(state) {
                return state.routeHistory;
            },
            siteMetaTitle(state) {
                return state.siteMetaTitle;
            }
        }
    });
};
