// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Transaction extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'driver', 'startedAt', 'status', 'token', 'total'
        ];
    }
}
