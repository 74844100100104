// Copyright © 2021 Move Closer
/**
 * Determines whether the passed-in argument properly implements the `AnyObject` interface.
 *
 * @param src - The object to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isAnyObject = (src) => typeof src === 'object' && src !== null;
