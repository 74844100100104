// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
export class Promotion extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'products'
        ];
    }
}
