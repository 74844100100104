// Copyright © 2021 Move Closer
import { ImageRatio } from '@d24/modules/front';
import { RelatedService, relatedTypeDriverRegistryFactory } from '@service/related';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export async function buildMetaTags(contentMeta, related) {
    var _a, _b;
    const meta = [];
    const metaTags = {
        ogType: {
            name: 'og:type',
            key: 'property'
        },
        ogUrl: {
            name: 'og:url',
            key: 'property'
        },
        ogDescription: {
            name: 'og:description',
            key: 'property'
        },
        ogImage: {
            name: 'og:image',
            key: 'property'
        },
        ogImageWidth: {
            name: 'og:image:width',
            key: 'property'
        },
        ogImageHeight: {
            name: 'og:image:height',
            key: 'property'
        },
        ogTitle: {
            name: 'og:title',
            key: 'property'
        },
        publicationDate: {
            name: 'article:published_time',
            key: 'property'
        },
        twitterDescription: {
            name: 'twitter:description',
            key: 'property'
        },
        twitterImage: {
            name: 'twitter:image',
            key: 'property'
        },
        twitterTitle: {
            name: 'twitter:title',
            key: 'property'
        },
        robots: { name: 'robots' }
    };
    const relatedService = new RelatedService(relatedTypeDriverRegistryFactory());
    relatedService.storeRelated(related);
    contentMeta.ogType = contentMeta.ogType || 'website';
    if (!contentMeta.robots) {
        contentMeta.robots = 'index, follow, max-image-preview:large';
    }
    else if (!contentMeta.robots.includes('max-image-preview')) {
        contentMeta.robots = contentMeta.robots + ', max-image-preview:large';
    }
    for (let [p, value] of Object.entries(contentMeta)) {
        const metaTag = metaTags[p];
        const name = metaTag ? (_a = metaTag.name) !== null && _a !== void 0 ? _a : p : p;
        const key = metaTag ? (_b = metaTag.key) !== null && _b !== void 0 ? _b : 'name' : 'name';
        try {
            if (value && typeof value !== 'string' && 'type' in value) {
                const selectedRatios = [];
                const resolved = await relatedService.resolve(value);
                for (let i = 0; i < resolved.srcset.length; i++) {
                    if (resolved.srcset[i].ratio === ImageRatio.Standard169) {
                        selectedRatios.push(resolved.srcset[i]);
                    }
                }
                if (selectedRatios.length === 0) {
                    for (let i = 0; i < resolved.srcset.length; i++) {
                        if (resolved.srcset[i].ratio === ImageRatio.Original) {
                            selectedRatios.push(resolved.srcset[i]);
                        }
                    }
                }
                selectedRatios.sort((a, b) => {
                    return b.width - a.width;
                });
                /**
                 * Filter out all ratios that are higher than original image dimensions.
                 */
                const filteredRatios = selectedRatios.filter(ratio => {
                    return Number(ratio.width) <= Number(resolved.width) && Number(ratio.height) <= Number(resolved.height);
                });
                value = resolved;
                if (filteredRatios.length > 0) {
                    value = filteredRatios[0];
                }
                if (value && typeof value !== 'string' && 'url' in value) {
                    /**
                     * Use og:image:width/height only if the image is filtered,
                     * meaning that original image has dimensions that are not
                     * higher than variant's dimensions.
                     */
                    if (p === 'ogImage' && 'width' in value && 'height' in value) {
                        meta.push({
                            property: 'og:image:width',
                            content: value.width.toString()
                        });
                        meta.push({
                            property: 'og:image:height',
                            content: value.height.toString()
                        });
                    }
                    if (value.url.indexOf('webp') > -1 && ('orgUrl' in value || resolved.orgMime)) {
                        value = value.orgUrl || (value === null || value === void 0 ? void 0 : value.url.replace(/(\.webp|\.jpg|\.png)/, `.${resolved.orgMime}`));
                    }
                    else {
                        value = value.url;
                    }
                    const cdnUrl = 'https://cdn.defence24.pl';
                    if (value && !value.startsWith('http')) {
                        value = `${cdnUrl}${value}`;
                    }
                }
            }
            if (value && typeof value !== 'string' && 'url' in value) {
                value = value.url;
            }
            const metaItem = {
                [key]: name,
                content: value
            };
            if (key === 'name') {
                meta.push(metaItem);
            }
            else {
                meta.push(metaItem);
            }
        }
        catch (e) {
            console.info(e);
        }
    }
    return meta;
}
