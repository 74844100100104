// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { User } from '../models/user';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let UserRepository = class UserRepository extends Repository {
    // protected map: MappingConfig = tokenAdapterMap
    // protected useAdapter = true
    async get() {
        var _a;
        const response = await this.connector.call('me', 'get', {}, {});
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
        return this.composeModel(response.data.data, User);
    }
    async update(data) {
        var _a;
        const response = await this.connector.call('me', 'update', {}, data);
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), response.data.errors);
        }
    }
    async setAvatar(avatar) {
        var _a;
        const formData = new FormData();
        formData.append('file', avatar);
        const response = await this.connector.call('me', 'setAvatar', {}, formData);
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
    }
};
UserRepository = __decorate([
    Injectable()
], UserRepository);
export { UserRepository };
