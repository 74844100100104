// Copyright © 2021 Move Closer
export const liveFeedAdapterMap = {
    content: 'content',
    createdAt: 'createdAt',
    deletedAt: 'deletedAt',
    embed: 'embed',
    feedId: 'feedId',
    gallery: 'gallery',
    id: 'id',
    image: 'image',
    reverseOrder: 'reverseOrder',
    updatedAt: 'updatedAt'
};
