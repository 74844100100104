// Copyright © 2021 Move Closer
import { isAnyObject } from '../../support/type-guards';
/**
 * Determines whether the passed-in object properly implements the EmailSharePayload interface.
 *
 * @param payload - The object to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isEmailSharePayload = (payload) => {
    if (!isAnyObject(payload)) {
        return false;
    }
    // Check the 'body' property.
    if (!Object.prototype.hasOwnProperty.call(payload, 'body') ||
        typeof payload.body !== 'string') {
        return false;
    }
    // Check the 'subject' property (optional).
    // noinspection RedundantIfStatementJS
    if (Object.prototype.hasOwnProperty.call(payload, 'subject') &&
        typeof payload.subject !== 'string') {
        return false;
    }
    return true;
};
/**
 * Determines whether the passed-in variable properly implements the FacebookShareConfig interface.
 *
 * @param config - The variable to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isFacebookShareConfig = (config) => {
    if (!isAnyObject(config)) {
        return false;
    }
    // Check the 'appId' property (required).
    if (!('appId' in config) || typeof config.appId !== 'string' || !config.appId.length) {
        return false;
    }
    return true;
};
/**
 * Determines whether the passed-in variable properly implements the FacebookSharePayload interface.
 *
 * @param payload - The variable to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isFacebookSharePayload = (payload) => {
    if (!isAnyObject(payload)) {
        return false;
    }
    // Check the 'hashtag' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'hashtag') && typeof payload.hashtag !== 'string') {
        return false;
    }
    // Check the 'url' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'url') && typeof payload.url !== 'string') {
        return false;
    }
    // Check the 'quote' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'quote') && typeof payload.quote !== 'string') {
        return false;
    }
    return true;
};
/**
 * Determines whether the passed-in variable properly implements the LinkedinSharePayload interface.
 *
 * @param payload - The variable to check.
 *
 * @typeGuard
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const isLinkedinSharePayload = (payload) => {
    if (!isAnyObject(payload)) {
        return false;
    }
    if (Object.prototype.hasOwnProperty.call(payload, 'url') && typeof payload.url !== 'string') {
        return false;
    }
    return true;
};
/**
 * Determines whether the passed-in variable properly implements the ShareServiceConfig interface.
 *
 * @param config - The variable to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isShareServiceConfig = (config) => {
    if (!isAnyObject(config)) {
        return false;
    }
    if (!Object.prototype.hasOwnProperty.call(config, 'facebook') || !isFacebookShareConfig(config.facebook)) {
        return false;
    }
    return true;
};
/**
 * Determines whether the passed-in variable properly implements the TwitterSharePayload interface.
 *
 * @param payload - The variable to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isTwitterSharePayload = (payload) => {
    if (!isAnyObject(payload)) {
        return false;
    }
    // Check the 'text' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'text') && typeof payload.text !== 'string') {
        return false;
    }
    // Check the 'url' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'url') && typeof payload.url !== 'string') {
        return false;
    }
    // Check the 'hashtags' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'hashtags') && typeof payload.hashtags !== 'string') {
        return false;
    }
    // Check the 'via' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'via') && typeof payload.via !== 'string') {
        return false;
    }
    // Check the 'related' property (optional).
    if (Object.prototype.hasOwnProperty.call(payload, 'related') && typeof payload.related !== 'string') {
        return false;
    }
    return true;
};
