// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, ResourceActionFailed, ResponseType } from '@movecloser/front-core';
import { saveAs } from 'file-saver';
import { EModal } from '@contract/modal';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let PdfService = class PdfService {
    constructor(connector, modalConnectorService, authService) {
        this.connector = connector;
        this.modalConnector = modalConnectorService;
        this.authService = authService;
    }
    downland(pdf) {
        let promise;
        if (pdf.public) {
            promise = this.getPublicPdf(pdf.path);
        }
        else {
            promise = this.getProtectedPdf(pdf.path);
        }
        if (promise && typeof promise.then === 'function') {
            promise.then(response => {
                saveAs(response);
            }).catch((e) => {
                if (e.status === 403) {
                    this.modalConnector.open(EModal.LogIn, {
                        onSuccess: () => {
                            this.getProtectedPdf(pdf.path).then(blob => {
                                saveAs(blob);
                            }).catch((e) => console.warn(e));
                        }
                    });
                }
            });
            return promise;
        }
    }
    async getPublicPdf(path) {
        const response = await fetch(path);
        if (response.status < 200 || response.status > 300) {
            throw new ResourceActionFailed(response.statusText, response.status);
        }
        return response.blob();
    }
    async getProtectedPdf(path) {
        var _a;
        const response = await this.connector.call('pdf', 'downloadProtected', { path }, {}, { Accept: 'application/octet-stream' }, ResponseType.Blob);
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, response.status);
        }
        return response.data;
    }
};
PdfService = __decorate([
    Injectable()
], PdfService);
export { PdfService };
