// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Site extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'address',
            'domain',
            'favicon',
            'id',
            'name',
            'locale',
            'logo',
            'properties',
            'searchLabels'
        ];
    }
    getProperty(key, defaultValue = null) {
        if (!this._data.properties[key]) {
            return defaultValue;
        }
        return this._data.properties[key];
    }
    isMaintenance() {
        return !!this.getProperty('maintenance', false);
    }
}
