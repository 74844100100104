// Copyright © 2021 Move Closer
import { Bootstrapper } from '@movecloser/front-core';
import { config } from '@config/index.ts';
import { createI18n } from './i18n';
import { createRouter } from './router';
import { createStore } from './store';
/**
 * Creates the application instance.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function createApp(context) {
    const bootstrapper = new Bootstrapper(config);
    await bootstrapper.boot();
    const configuration = bootstrapper.getConfiguration();
    const container = bootstrapper.getContainer();
    const routesStack = bootstrapper.getRoutesStack();
    const storeStack = bootstrapper.getStoreStack();
    const router = createRouter(routesStack, container);
    const store = createStore(storeStack);
    const i18n = await createI18n();
    return { configuration, container, i18n, router, store };
}
