// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export var ConnectorErrors;
(function (ConnectorErrors) {
    ConnectorErrors["Conflict"] = "conflict";
    ConnectorErrors["Forbidden"] = "forbidden";
    ConnectorErrors["NotFound"] = "notfound";
    ConnectorErrors["PaymentRequired"] = "PaymentRequired";
    ConnectorErrors["ServerError"] = "ServerError";
    ConnectorErrors["TemporaryUnavailable"] = "TemporaryUnavailable";
    ConnectorErrors["Validation"] = "validation";
    ConnectorErrors["Unauthenticated"] = "unauthenticated";
    ConnectorErrors["Unknown"] = "unknown";
})(ConnectorErrors || (ConnectorErrors = {}));
/**
 * TODO: Description.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resolveFromStatus = (response) => {
    switch (typeof response === 'number' ? response : response.status) {
        case 401:
            return ConnectorErrors.Unauthenticated;
        case 402:
            return ConnectorErrors.PaymentRequired;
        case 403:
            return ConnectorErrors.Forbidden;
        case 404:
            return ConnectorErrors.NotFound;
        case 409:
            return ConnectorErrors.Conflict;
        case 422:
            return ConnectorErrors.Validation;
        case 500:
            return ConnectorErrors.ServerError;
        case 503:
            return ConnectorErrors.TemporaryUnavailable;
        default:
            return ConnectorErrors.Unknown;
    }
};
