import { __decorate } from "tslib";
import { AbstractElement } from '@d24/page-builder';
import { createDeMarked, ModuleBackgroundColor } from '@d24/modules/front';
import { Component, Prop } from 'vue-property-decorator';
import { EventbusType, WindowType } from '@movecloser/front-core';
import LazyHydrate from 'vue-lazy-hydration';
import { CapturesError } from '@support/handle-errors';
import { PdfServiceType } from '@service/pdf';
import { ShareServiceType } from '@service/share';
import { SiteServiceType } from '@service/site';
import { Inject } from '@plugin/inversify';
import { Hydration } from '@component/PageBuilder';
import { SubscriptionServiceType } from '@subscription/shared/services';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ModuleWrapper = class ModuleWrapper extends AbstractElement {
    constructor() {
        super(...arguments);
        /**
         * Determines whether the module should work in the `debug` mode.
         */
        this.debug = process.env.VUE_APP_DEBUG_MODULES === 'true';
        /**
         * An instance of the `DeMarked` class.
         */
        this.DeMarked = createDeMarked();
    }
    /**
     * The Vue component that will be used to display the element's contents.
     */
    get component() {
        var _a;
        const registry = (_a = this.$root.$options.configuration) === null || _a === void 0 ? void 0 : _a.byKey('pageBuilder.components');
        const component = registry[this._inner.driver];
        if (!component || typeof component !== 'function') {
            this.$logger(`[<ModuleWrapper>.component]: There's no Vue component registered for the [${this._inner.driver}] driver!`, 'warn');
        }
        return component;
    }
    get fallbackImage() {
        const site = this.siteService.getActiveSite();
        if (!site.properties.fallback || typeof site.properties.fallback !== 'string') {
            return require('@/assets/images/placeholders/image.png');
        }
        return site.properties.fallback;
    }
    get hydration() {
        var _a;
        const config = (_a = this.$root.$options.configuration) === null || _a === void 0 ? void 0 : _a.byKey('pageBuilder');
        const type = this._inner.driver in config.hydration
            ? (config.hydration[this._inner.driver] || config.defaultHydration) : config.defaultHydration;
        switch (type) {
            case Hydration.WhenVisible:
                return { whenVisible: { rootMargin: '200px' } };
            default:
                return { [type]: true };
        }
    }
    /**
     * The module's contents.
     */
    get module() {
        return this._inner;
    }
    /**
     * Current application theme.
     */
    get theme() {
        return this.siteService.getActiveSiteTheme();
    }
    /**
     * @returns The initial size of the passed-in `inner` element.
     * @param inner - The element which initial size we want top resolve.
     */
    static resolveInitialSize(inner) {
        return inner.size;
    }
    static resolveClassName(inner) {
        return (inner.size.colSpan === 6 &&
            inner.content &&
            (inner.content.backgroundColor || (inner.content.backgroundColor === ModuleBackgroundColor.Custom && inner.content.background) || inner.content.background || inner.content.backgroundObj) &&
            ((inner.content.backgroundColor === ModuleBackgroundColor.Custom && inner.content.background !== '') || inner.content.backgroundColor !== ModuleBackgroundColor.None || typeof inner.content.backgroundObj === 'object')
            ? 'block--colored'
            : '');
    }
    decorateError(error) {
        error.message = `[${this.module.driver}] ${this.module.id} / ${error.message}`;
        return error;
    }
};
/**
 * The HTML tag that should be used as the hosting HTML element.
 */
ModuleWrapper.tag = 'div';
__decorate([
    Prop({
        type: Object,
        required: false
    })
], ModuleWrapper.prototype, "parent", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    })
], ModuleWrapper.prototype, "relatedService", void 0);
__decorate([
    Prop({
        type: Object,
        required: false
    })
], ModuleWrapper.prototype, "size", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: true,
        default: false
    })
], ModuleWrapper.prototype, "isMobile", void 0);
__decorate([
    Inject(PdfServiceType)
], ModuleWrapper.prototype, "pdfService", void 0);
__decorate([
    Inject(EventbusType)
], ModuleWrapper.prototype, "eventBus", void 0);
__decorate([
    Inject(ShareServiceType)
], ModuleWrapper.prototype, "shareService", void 0);
__decorate([
    Inject(SiteServiceType)
], ModuleWrapper.prototype, "siteService", void 0);
__decorate([
    Inject(SubscriptionServiceType)
], ModuleWrapper.prototype, "subscriptionService", void 0);
__decorate([
    Inject(WindowType)
], ModuleWrapper.prototype, "windowService", void 0);
ModuleWrapper = __decorate([
    Component({
        inheritAttrs: false,
        name: 'ModuleWrapper',
        components: { LazyHydrate },
        mixins: [CapturesError]
    })
], ModuleWrapper);
export { ModuleWrapper };
export default ModuleWrapper;
