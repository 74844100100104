// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { SneakPeak } from '../models/sneak-peak';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SearchRepository = class SearchRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = {};
        this.useAdapter = false;
    }
    async load(query) {
        var _a;
        const response = await this.connector.call('search', 'load', {}, query);
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), response.data.errors);
        }
        return this.composeCollection(response.data.data, SneakPeak, response.data.meta);
    }
};
SearchRepository = __decorate([
    Injectable()
], SearchRepository);
export { SearchRepository };
