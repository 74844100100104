import { AbstractEvent } from '@kluseg/trackify/dist/main';
export class StaticAdClickEvent extends AbstractEvent {
    constructor(config) {
        super(config);
        this.config = config;
        this.name = 'custom.static_ad_click';
    }
    forDriver(driverName) {
        if (driverName === 'GTMBrowserDriver') {
            return {
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_name: 'static_ad_click',
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_payload: this.getData(),
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_type: 'common'
            };
        }
        return null;
    }
    getData() {
        return { ...this.config };
    }
}
