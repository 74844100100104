// Copyright © 2021 Move Closer
var Level;
(function (Level) {
    Level["Debug"] = "debug";
    Level["Error"] = "error";
    Level["Info"] = "info";
    Level["Log"] = "log";
    Level["Warn"] = "warn";
})(Level || (Level = {}));
const levelsMap = {
    [Level.Debug]: [...Object.values(Level)],
    [Level.Error]: [Level.Error],
    [Level.Info]: [Level.Error, Level.Info, Level.Warn],
    [Level.Log]: [Level.Error, Level.Info, Level.Log, Level.Warn],
    [Level.Warn]: [Level.Error, Level.Warn]
};
/**
 * Logs the passed-in message to the console.
 *
 * @param message - The content (message) to be logged.
 * @param [level="debug"] - Information level.
 * @param [logOnServer=true] - Whether the output should be visible
 *   in the Node server's console, too.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const log = (message, level = 'info', logOnServer = true) => {
    let logLevel = process.env.VUE_APP_LOG_LEVEL || Level.Error;
    if (!Object.values(Level).includes(logLevel)) {
        logLevel = Level.Error;
    }
    if (process.env.VUE_APP_DEBUG_CONSOLE !== 'true' ||
        (typeof window === 'undefined' && !logOnServer) ||
        !levelsMap[logLevel].includes(level)) {
        return;
    }
    if (Array.isArray(message)) {
        console[level](...message);
    }
    else {
        console[level](message);
    }
};
