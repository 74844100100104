// Copyright © 2021 Move Closer
import { AuthorDriver, ContentDriver, DeduplicatedSetDriver, FileDriver, GalleryDriver, LabelDriver, NewsletterDriver, RelatedType, SetDriver, SmartAuthorDriver, SmartCategoryDriver, SmartGalleryDriver, SmartTagDriver, TagDriver } from '@d24/modules/front';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const relatedTypeDriverRegistryFactory = () => ({
    [RelatedType.Author]: new AuthorDriver(),
    [RelatedType.Content]: new ContentDriver(),
    [RelatedType.DeduplicatedSet]: new DeduplicatedSetDriver(),
    [RelatedType.ExtendedAuthor]: new AuthorDriver(),
    [RelatedType.Newsletter]: new NewsletterDriver(),
    [RelatedType.File]: new FileDriver(),
    [RelatedType.Label]: new LabelDriver(),
    [RelatedType.Gallery]: new GalleryDriver(),
    [RelatedType.Set]: new SetDriver(),
    [RelatedType.SmartAuthor]: new SmartAuthorDriver(),
    [RelatedType.SmartCategory]: new SmartCategoryDriver(),
    [RelatedType.SmartGallery]: new SmartGalleryDriver(),
    [RelatedType.SmartTag]: new SmartTagDriver(),
    [RelatedType.Tag]: new TagDriver()
});
