// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { ApiConnectorType, AppModule, Module } from '@movecloser/front-core';
import { CommentRepositoryType, ContentRepositoryType, FeedEntriesRepositoryType, LiveFeedRepositoryType, SearchRepositoryType } from './contracts';
import { SupportedFormatsMiddleware, SupportedFormatsMiddlewareType } from '@content/services';
import { CommentRepository } from './repositories/comments';
import { ContentRepository } from './repositories/content';
import { LiveFeedsRepository } from './repositories/live-feed';
import { FeedEntriesRepository } from './repositories/feed-entries';
import { SearchRepository } from './repositories/search';
import { resources } from './resources';
import { routesFactory as routes } from './routes';
import { storeFactory as state } from './store';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
let ContentModule = class ContentModule extends Module {
};
ContentModule = __decorate([
    AppModule({
        name: 'content',
        routes,
        state,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        providers: (config) => {
            return (bind) => {
                bind(CommentRepositoryType).to(CommentRepository);
                bind(ContentRepositoryType).to(ContentRepository);
                bind(LiveFeedRepositoryType).to(LiveFeedsRepository);
                bind(FeedEntriesRepositoryType).to(FeedEntriesRepository);
                bind(SearchRepositoryType).to(SearchRepository);
                bind(SupportedFormatsMiddlewareType).to(SupportedFormatsMiddleware).inSingletonScope();
            };
        },
        boot: (container) => {
            const connector = container.get(ApiConnectorType);
            connector.useResources(resources);
        }
    })
], ContentModule);
export { ContentModule };
