// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export var Domain;
(function (Domain) {
    Domain["CyberDefence24"] = "cyberdefence24.pl";
    Domain["Defence24"] = "defence24.pl";
    Domain["PremiumDefence24"] = "premium.defence24.pl";
    Domain["Defence24COM"] = "defence24.com";
    Domain["Defence24MSPO"] = "mspo.defence24.pl";
    Domain["Defence24Premium"] = "premium.defence24.pl";
    Domain["Defence24TV"] = "defence24.tv";
    Domain["Energetyka24"] = "energetyka24.com";
    Domain["InfoSecurity24"] = "infosecurity24.pl";
    Domain["Space24"] = "space24.pl";
    Domain["Unknown"] = "unknown";
})(Domain || (Domain = {}));
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export var SiteFaviconType;
(function (SiteFaviconType) {
    SiteFaviconType["Png"] = "png";
    SiteFaviconType["Svg"] = "svg";
})(SiteFaviconType || (SiteFaviconType = {}));
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export var SiteLogoVariant;
(function (SiteLogoVariant) {
    SiteLogoVariant["Dark"] = "dark";
    SiteLogoVariant["Light"] = "light";
})(SiteLogoVariant || (SiteLogoVariant = {}));
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteMiddlewareType = Symbol.for('SiteMiddleware');
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteServiceType = Symbol.for('ISiteService');
