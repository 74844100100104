// Copyright © 2021 Move Closer
const allowedQueryParams = ['page', 'secret'];
/**
 * Determines whether the given URL points to the file, instead of a resource.
 *
 * @param url - The URL to check.
 */
export function isCallingForFile(url) {
    const reg = /[^.]*(\.[\w\d]+)*(\.[\w]{1,4})$/g;
    const result = reg.exec(url);
    return result !== null;
}
/**
 * Trim slug from not allowed query params.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function trimSlugQueryParams(slug, allowed = allowedQueryParams) {
    const slugSegments = slug.split('?');
    if (slugSegments.length < 2) {
        return slug;
    }
    const decoded = new URLSearchParams(slugSegments[1]);
    for (const key of [...decoded.keys()]) {
        if (allowed.includes(key)) {
            continue;
        }
        decoded.delete(key);
    }
    slugSegments[1] = decoded.toString();
    return slugSegments.join('?');
}
/**
 * Trim slug for Registry call.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function trimSlugForRegistry(slug) {
    slug = trimSlugQueryParams(slug);
    if (slug[0] === '/') {
        slug = slug.substring(1);
    }
    return slug;
}
