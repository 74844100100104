// Copyright © 2021 Move Closer
import { AxiosDriver } from '@movecloser/front-core/lib/services/http/axios-driver';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const http = {
    drivers: {
        d24: () => {
            return new AxiosDriver({
                baseURL: process.env.VUE_APP_HTTP_D24_API_BASEURL,
                headers: {
                    Accept: 'application/json',
                    'X-Client': process.env.VUE_APP_HTTP_D24_API_CLIENT
                }
            }, process.env.VUE_APP_DEBUG_HTTP === 'true');
        }
    },
    default: 'd24'
};
