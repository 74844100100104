// Copyright © 2021 Move Closer
/**
 * This method validates if app context is properly constructed.
 *
 * @param context
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const validateAppContext = (context) => {
    const { app, error } = context;
    if (typeof app === 'undefined') {
        const message = '[validateAppContext]: FATAL ERROR! Failed to resolve the app instance!';
        if (process.env.VUE_APP_ENV === 'local') {
            throw new Error(message);
        }
        else {
            error(message, 500);
            return;
        }
    }
    const container = app.$container;
    if (typeof container === 'undefined') {
        const message = '[validateAppContext]: FATAL ERROR! Failed to resolve the IOC Container!';
        if (process.env.VUE_APP_ENV === 'local') {
            throw new Error(message);
        }
        else {
            error(message, 500);
        }
    }
};
