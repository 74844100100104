// Copyright © 2021 Move Closer
export var LiveFeedStatus;
(function (LiveFeedStatus) {
    LiveFeedStatus["Live"] = "live";
    LiveFeedStatus["New"] = "new";
    LiveFeedStatus["Ended"] = "ended";
})(LiveFeedStatus || (LiveFeedStatus = {}));
export var FeedDirection;
(function (FeedDirection) {
    FeedDirection["After"] = "after";
    FeedDirection["Before"] = "before";
})(FeedDirection || (FeedDirection = {}));
