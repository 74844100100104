// Copyright © 2021 Move Closer
import { Methods } from '@movecloser/front-core';
export const resources = {
    content: {
        connection: 'd24',
        prefix: 'registry',
        methods: {
            get: {
                url: '{slug}',
                method: Methods.Get,
                params: ['slug'],
                auth: true
            }
        }
    },
    paywall: {
        connection: 'd24',
        prefix: 'paywall',
        methods: {
            check: {
                url: '',
                method: Methods.Get,
                auth: true
            }
        }
    },
    pdf: {
        connection: 'd24',
        prefix: '',
        methods: {
            downloadPublic: {
                url: '{path}',
                params: ['path'],
                auth: false,
                method: Methods.Get
            },
            downloadProtected: {
                url: '{path}',
                params: ['path'],
                auth: true,
                method: Methods.Get
            }
        }
    },
    setup: {
        connection: 'd24',
        prefix: '',
        methods: {
            init: {
                url: 'init',
                method: Methods.Get,
                meta: { omitSite: true }
            }
        }
    },
    backup: {
        connection: 'd24',
        prefix: '',
        methods: {
            backupData: {
                url: 'user-com-backup',
                method: Methods.Post,
                auth: true
            }
        }
    },
    forms: {
        connection: 'd24',
        prefix: '',
        methods: {
            send: {
                url: 'forms',
                method: Methods.Post,
                auth: true
            }
        }
    }
};
