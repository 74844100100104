// Copyright © 2021 Move Closer
import { log } from '@support/log';
let Vue;
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function install(_Vue) {
    if (Vue && _Vue === Vue) {
        if (process.env.NODE_ENV !== 'production') {
            /* eslint no-console: off */
            console.error('[logger] already installed.');
        }
    }
    else {
        _Vue.mixin({
            beforeCreate: function () {
                this.$logger = log;
            }
        });
    }
}
export default { install };
