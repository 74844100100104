// Copyright © 2023 Move Closer
/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const socials = {
    Facebook: 'facebook',
    // Twitter: 'twitter',
    Google: 'google'
    // LinkedIn: 'linkedin'
};
