// Copyright © 2021 Move Closer
import { Domain } from '@service/site';
/**
 * Declares the main value for the <title> element for each of the supported domains.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const domainTitleRegistry = {
    [Domain.CyberDefence24]: 'CyberDefence24',
    [Domain.Defence24COM]: 'Defence24.com',
    [Domain.Defence24MSPO]: 'Defence24 MSPO',
    [Domain.Defence24Premium]: 'Defence24 Premium',
    [Domain.Defence24TV]: 'Defence24 TV',
    [Domain.Defence24]: 'Defence24',
    [Domain.Energetyka24]: 'Energetyka24',
    [Domain.InfoSecurity24]: 'InfoSecurity24',
    [Domain.Space24]: 'Space24',
    [Domain.PremiumDefence24]: 'Defence24 Premium',
    [Domain.Unknown]: 'Defence24'
};
/**
 * Various configurations connected with the domain topic.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const domain = {
    defaultDomain: Domain.Defence24,
    initialDomain: Domain.Unknown,
    titleRegistry: domainTitleRegistry
};
