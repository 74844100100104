// Copyright © 2021 Move Closer
import { Methods } from '@movecloser/front-core';
/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const resources = {
    feeds: {
        connection: 'd24',
        prefix: '',
        methods: {
            fetch: {
                url: 'content/feeds/{id}',
                params: ['id'],
                method: Methods.Get
            },
            entries: {
                url: 'content/feeds/{id}/entries',
                params: ['id'],
                method: Methods.Get
            },
            entry: {
                url: 'content/feeds/{id}/entries/{entry}',
                params: ['id', 'entry'],
                method: Methods.Get
            }
        }
    },
    comments: {
        prefix: '',
        methods: {
            add: {
                url: 'comments',
                method: Methods.Post,
                auth: true
            },
            list: {
                url: 'comments',
                method: Methods.Get
            },
            delete: {
                url: 'comments',
                method: Methods.Delete,
                auth: true
            }
        }
    },
    search: {
        prefix: '',
        methods: {
            load: {
                url: 'search',
                method: Methods.Get,
                auth: true
            }
        }
    }
};
