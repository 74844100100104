// Copyright © 2021 Move Closer
import { MappingTypes } from '@movecloser/front-core';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const contentAdapterMap = {
    author: 'author',
    containers: 'containers',
    categoryUrl: 'categoryUrl',
    contentId: 'contentId',
    options: 'contentProperties',
    parent: 'parent',
    properties: 'properties',
    publicationDate: 'publicationDate',
    site: 'site',
    slots: 'slots',
    title: 'title',
    type: 'type',
    urlPath: 'urlPath',
    pdf: {
        type: MappingTypes.Function,
        value: item => {
            if (!item.pdf) {
                return null;
            }
            else {
                return {
                    path: item.pdf.url,
                    public: item.pdf.public
                };
            }
        }
    }
};
