import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let SidebarNav = class SidebarNav extends Vue {
};
__decorate([
    Prop({ type: Array, required: true })
], SidebarNav.prototype, "links", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], SidebarNav.prototype, "isSubmenu", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: true })
], SidebarNav.prototype, "isOpen", void 0);
SidebarNav = __decorate([
    Component({
        name: 'SidebarNav',
        components: { SidebarItem: () => import('../SidebarItem/SidebarItem.vue') }
    })
], SidebarNav);
export { SidebarNav };
export default SidebarNav;
