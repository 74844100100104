// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { AppModule, EventbusType, Module, WindowType } from '@movecloser/front-core';
import { GAEventObserverType, GAObserver } from './observers';
/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
let AnalyticsModule = class AnalyticsModule extends Module {
};
AnalyticsModule = __decorate([
    AppModule({
        name: 'analytics',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        providers: (config) => {
            return (bind) => {
                bind(GAEventObserverType).toDynamicValue(context => new GAObserver(context.container.get(WindowType))).inSingletonScope();
            };
        },
        boot: (container) => {
            const eventBus = container.get(EventbusType);
            const GAObserver = container.get(GAEventObserverType);
            eventBus.observe(GAObserver);
        }
    })
], AnalyticsModule);
export { AnalyticsModule };
