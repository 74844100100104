// Copyright © 2021 Move Closer
import { EModal } from '@contract/modal';
/**
 * Duration of the modal's transition (animation) (in ms).
 */
export const MODAL_TRANSITION_DURATION = 300;
/**
 * Registry of all available modals.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const modalsRegistry = {
    [EModal.AccountNotFound]: () => import(/* webpackChunkName: "modal/AccountNotFoundModal" */ '@profile/shared/components/AccountNotFoundModal/AccountNotFoundModal.vue'),
    [EModal.ActivationLinkSent]: () => import(/* webpackChunkName: "modal/ActivationLinkSentModal" */ '@profile/shared/components/ActivationLinkSentModal/ActivationLinkSentModal.vue'),
    [EModal.BuySubscription]: () => import(/* webpackChunkName: "modal/BuySubscriptionModal" */ '@subscription/shared/components/BuySubscriptionModal.vue'),
    [EModal.BuySingleAccess]: () => import(/* webpackChunkName: "modal/BuySingleAccessModal" */ '@subscription/shared/components/BuySingleAccessModal.vue'),
    [EModal.BuySingleAccessSteps]: () => import(/* webpackChunkName: "modal/BuySingleAccessModal" */ '@subscription/shared/components/BuySingleAccessStepsModal.vue'),
    [EModal.ForgotPassword]: () => import(/* webpackChunkName: "modal/ForgotPasswordModal" */ '@profile/shared/components/ForgotPasswordModal/ForgotPasswordModal.vue'),
    [EModal.FullNameSetup]: () => import(/* webpackChunkName: "modal/FullNameSetupModal" */ '@profile/shared/FullNameSetupModal.vue'),
    [EModal.Info]: () => import(/* webpackChunkName: "modal/InfoModal" */ '@component/Modal/InfoModal/InfoModal.vue'),
    [EModal.LogInError]: () => import(/* webpackChunkName: "modal/LogInErrorModal" */ '@profile/shared/components/LogInErrorModal/LogInErrorModal.vue'),
    [EModal.LogIn]: () => import(/* webpackChunkName: "modal/LogInModal" */ '@profile/shared/components/LogInModal/LogInModal.vue'),
    [EModal.MessageSent]: () => import(/* webpackChunkName: "modal/MessageSentModal" */ '@profile/shared/components/MessageSentModal/MessageSentModal.vue'),
    [EModal.NoNicknameSet]: () => import(/* webpackChunkName: "modal/NoNicknameSet" */ '@profile/shared/components/NoNicknameSetModal/NoNicknameSetModal.vue'),
    [EModal.NoAccountModal]: () => import(/* webpackChunkName: "modal/NoAccountModal" */ '@profile/shared/components/NoAccountModal/NoAccountModal.vue'),
    [EModal.NotActiveAccountModal]: () => import(/* webpackChunkName: "modal/NotActiveAccountModal" */ '@profile/shared/components/NotActiveAccountModal/NotActiveAccountModal.vue'),
    [EModal.SubscriptionConflict]: () => import(/* webpackChunkName: "modal/SubscriptionConflictModal" */ '@subscription/shared/components/SubscriptionConflictModal.vue'),
    [EModal.PaymentError]: () => import(/* webpackChunkName: "modal/PaymentError" */ '@profile/shared/components/PaymentErrorModal/PaymentErrorModal.vue'),
    [EModal.SetNickname]: () => import(/* webpackChunkName: "modal/SetNickname" */ '@profile/shared/components/SetNicknameModal/SetNicknameModal.vue'),
    [EModal.Welcome]: () => import(/* webpackChunkName: "modal/WelcomeModal" */ '@profile/shared/components/WelcomeModal/WelcomeModal.vue')
};
