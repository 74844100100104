// Copyright © 2021 Move Closer
import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const ProgressBarPlugin = {
    async beforeCreate({ isClient }) {
        if (isClient) {
            Vue.use(VueProgressBar, {
                color: '#00ab6c',
                failedColor: '#c4382c',
                thickness: '2px'
            });
        }
    },
    async beforeStart({ app, isClient, router }) {
        if (isClient && typeof app !== 'undefined') {
            // On boot start progress
            app.$Progress.start();
            // On route change start progress
            router.beforeEach((to, from, next) => {
                app.$Progress.start();
                next();
            });
            // On route rendered: stop
            router.afterEach(() => {
                app.$Progress.finish();
            });
            // On route error: fail
            router.onError(() => {
                app.$Progress.fail();
            });
            // On redirect: stop
            app.$on('router.redirect', () => {
                app.$Progress.finish();
            });
        }
    },
    catchError({ app, isClient }) {
        if (isClient && typeof app !== 'undefined') {
            app.$Progress.fail();
        }
    },
    // On route error: fail
    async routeError({ app, isClient }) {
        if (isClient && typeof app !== 'undefined') {
            app.$Progress.fail();
        }
    },
    // When all is ready: stop
    ready({ app, isClient }) {
        if (isClient && typeof app !== 'undefined') {
            app.$Progress.finish();
        }
    }
};
export default ProgressBarPlugin;
