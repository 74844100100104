var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LazyHydrate',_vm._b({},'LazyHydrate',_vm.hydration,false),[_c(_vm.component,_vm._b({tag:"component"},'component',{
             DeMarked: _vm.DeMarked,
             debug: _vm.debug,
             eventBus: _vm.eventBus,
             fallbackImage: _vm.fallbackImage,
             module: _vm.module,
             parent: _vm.parent,
             pdfService: _vm.pdfService,
             relatedService: _vm.relatedService,
             siteService: _vm.siteService,
             shareService: _vm.shareService,
             subscriptionService: _vm.subscriptionService,
             theme: _vm.theme,
             windowService: _vm.windowService,
             isMobile: _vm.isMobile
           },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }