// Copyright © 2021 Move Closer
import { ClearLayout } from './clear';
import { DefaultLayout } from './default';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const defaultLayoutKey = 'default';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const availableLayouts = {
    clear: ClearLayout,
    [defaultLayoutKey]: DefaultLayout
};
