// Copyright © 2021 Move Closer
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["BLIK"] = "blik";
    PaymentMethod["CreditCard"] = "credit_card";
    PaymentMethod["OnlineTransfer"] = "online_transfer";
    PaymentMethod["StandardTransfer"] = "standard_transfer";
})(PaymentMethod || (PaymentMethod = {}));
/**
 * Step identifier.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export var Step;
(function (Step) {
    Step["PaymentMethod"] = "PaymentMethod";
    Step["Status"] = "Status";
    Step["Summary"] = "Summary";
})(Step || (Step = {}));
