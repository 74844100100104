// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { Promotion } from '../models/promotion';
let PromotionsRepository = class PromotionsRepository extends Repository {
    constructor() {
        super(...arguments);
        this.useAdapter = false;
    }
    async apply(code, products) {
        var _a, _b, _c, _d;
        const response = await this.connector.call('promotions', 'apply', {}, {
            code,
            products
        });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_b = (_a = response.data.data) === null || _a === void 0 ? void 0 : _a.errorMessage) !== null && _b !== void 0 ? _b : (_c = response.errors) === null || _c === void 0 ? void 0 : _c.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_d = response.errors) === null || _d === void 0 ? void 0 : _d.errors);
        }
        return this.composeModel(response.data.data, Promotion);
    }
};
PromotionsRepository = __decorate([
    Injectable()
], PromotionsRepository);
export { PromotionsRepository };
