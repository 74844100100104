// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let IdentityMiddleware = class IdentityMiddleware {
    constructor(identity) {
        this.identity = identity;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    afterCall(response) {
        //
    }
    beforeCall(resource, headers, body) {
        if (!this.identity.hasSignature()) {
            return { body, headers };
        }
        return {
            body,
            headers: {
                ...headers,
                'X-Viewer': this.identity.getSignature()
            }
        };
    }
};
IdentityMiddleware = __decorate([
    Injectable()
], IdentityMiddleware);
export { IdentityMiddleware };
