// Copyright © 2021 Move Closer
import { AppModules as modules } from './modules';
import { auth } from './auth';
import { domain } from './domain';
import { http } from './http';
import { middlewares } from './middlewares';
import { pageBuilder } from './page-builder';
import { payments } from './payments';
import { resources } from './resources';
import { ROUTER_DRIVER as router } from './router';
import { services } from './services';
import { STORE_DRIVER as store } from './store';
import { socials } from './socials';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const config = {
    auth,
    domain,
    http,
    middlewares,
    modals: {},
    modules,
    pageBuilder,
    payments,
    resources,
    router,
    services,
    store,
    socials,
    // Has to be defined, even if it's empty, or an error will be thrown.
    modalConfig: {}
};
