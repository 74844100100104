import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { Inject } from '@plugin/inversify';
import { MODAL_TRANSITION_DURATION } from '@config/modal';
import { modalWrapperConfig } from './ModalWrapperConfig';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let ModalWrapper = class ModalWrapper extends Vue {
    constructor() {
        super(...arguments);
        /**
         * Chosen modal's Vue component.
         */
        this.component = null;
        /**
         * Chosen modal's config.
         */
        this.config = {};
        /**
         * Determines whether the modal should be open.
         */
        this.open = false;
        /**
         * Chosen modal's payload (data).
         */
        this.payload = null;
    }
    /**
     * Vue lifecycle hook.
     */
    mounted() {
        this.modalConnector.subscribe((modalState) => {
            if (this.modalConnector.isOpened) {
                this.config = {
                    ...modalWrapperConfig,
                    ...modalState.config
                };
                this.component = this.modalConnector.getComponent(String(this.modalConnector.name));
                this.payload = modalState.payload;
            }
            this.open = modalState.opened;
        });
    }
    /**
     * Determines whether the modal should be closable.
     */
    get closable() {
        if (Object.prototype.hasOwnProperty.call(this.config, 'closable') &&
            typeof this.config.closable === 'boolean') {
            return this.config.closable;
        }
        return true;
    }
    /**
     * TODO: Add support of size to D24Modal.
     */
    get size() {
        if (Object.prototype.hasOwnProperty.call(this.config, 'size') &&
            typeof this.config.size === 'string') {
            return this.config.size;
        }
        return '';
    }
    /**
     * Handles the `@close` event on the `<D24Modal>`.
     */
    onClose() {
        this.closeModal();
        setTimeout(() => { this.clearModalData(); }, MODAL_TRANSITION_DURATION);
    }
    /**
     * Clears the inner modal's data.
     */
    clearModalData() {
        this.component = null;
        this.config = {};
        this.open = false;
        this.payload = null;
    }
    /**
     * Closes the modal.
     */
    closeModal() {
        this.modalConnector.close('');
    }
    clickedOutside() {
        var _a;
        if ((_a = this.config.closableWithOutsideClick) !== null && _a !== void 0 ? _a : true) {
            this.onClose();
        }
    }
};
__decorate([
    Inject(ModalType)
], ModalWrapper.prototype, "modalConnector", void 0);
ModalWrapper = __decorate([
    Component({ name: 'ModalWrapper' })
], ModalWrapper);
export { ModalWrapper };
export default ModalWrapper;
