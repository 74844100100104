// Copyright © 2022 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export var Env;
(function (Env) {
    Env["Local"] = "local";
    Env["Production"] = "production";
    Env["Rc"] = "rc";
    Env["Stage"] = "stage";
})(Env || (Env = {}));
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
const nonProductionEnvs = [Env.Local, Env.Stage, Env.Rc];
const productionEnds = [Env.Production];
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function isDevelopmentEnv() {
    return process.env.VUE_APP_ENV === 'local';
}
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function isNonDevelopmentEnv() {
    return process.env.VUE_APP_ENV !== 'local';
}
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function isNonProductionEnv() {
    return nonProductionEnvs.includes(process.env.VUE_APP_ENV);
}
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function isProductionEnv() {
    return productionEnds.includes(process.env.VUE_APP_ENV);
}
