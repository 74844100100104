// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import browserSignature from 'browser-signature';
import { Injectable, WindowService } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let IdentityService = class IdentityService {
    constructor() {
        this.signature = '';
        this.signatureKey = 'x-viewer';
    }
    generateSignature() {
        if (!WindowService.isDefined) {
            return;
        }
        let foundValue = this.retrieve();
        if (!foundValue) {
            foundValue = browserSignature();
            this.store(foundValue);
        }
        this.signature = foundValue;
    }
    getSignature() {
        if (!this.hasSignature()) {
            this.generateSignature();
        }
        return this.signature;
    }
    hasSignature() {
        return this.signature.length > 0;
    }
    retrieve() {
        return window.localStorage.getItem(this.signatureKey) || window.sessionStorage.getItem(this.signatureKey);
    }
    store(value) {
        window.localStorage.setItem(this.signatureKey, value);
        window.sessionStorage.setItem(this.signatureKey, value);
    }
};
IdentityService = __decorate([
    Injectable()
], IdentityService);
export { IdentityService };
