import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
/**
 * Component capable of rendering the error details
 * using the `$errorHandler` property from the _UVue_ package.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let ErrorDetails = class ErrorDetails extends Vue {
    /**
     * Vue lifecycle hook.
     */
    mounted() {
        if (this.$errorHandler.error) {
            this.$logger(this.$errorHandler.error.stack, 'error');
        }
    }
    /**
     * Error code.
     */
    get errorCode() {
        if (typeof this.$errorHandler.statusCode !== 'number') {
            return;
        }
        return this.$errorHandler.statusCode;
    }
    /**
     * Human-readable description of the error occurred.
     */
    get errorMessage() {
        const translations = this.$t('components.error-details.codes');
        if (typeof this.errorCode !== 'number' || !Object.prototype.hasOwnProperty.call(translations, this.errorCode)) {
            // @ts-expect-error
            return translations.default;
        }
        // @ts-expect-error
        return translations[this.errorCode];
    }
    get showDebugDetails() {
        return process.env.VUE_APP_DEBUG_CONSOLE === 'true';
    }
};
ErrorDetails = __decorate([
    Component({
        name: 'ErrorDetails',
        metaInfo() {
            return { title: this.$t('_.error') };
        }
    })
], ErrorDetails);
export { ErrorDetails };
export default ErrorDetails;
