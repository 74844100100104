// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { ApiConnectorType, AppModule, Module } from '@movecloser/front-core';
import { AuthRepository } from './repositories/auth';
import { AuthRepositoryType, CardsRepositoryType, UserRepositoryType } from './contracts/repositories';
import { resources } from './resources';
import { routesFactory as routes } from './routes';
import { storeFactory as state } from './store';
import { UserRepository } from './repositories/me';
import { CardsRepository } from '@profile/repositories/cards';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let ProfileModule = class ProfileModule extends Module {
};
ProfileModule = __decorate([
    AppModule({
        name: 'profile',
        routes,
        state,
        providers: () => (bind) => {
            bind(AuthRepositoryType).to(AuthRepository);
            bind(UserRepositoryType).to(UserRepository);
            bind(CardsRepositoryType).to(CardsRepository);
        },
        boot: (container) => {
            const connector = container.get(ApiConnectorType);
            connector.useResources(resources);
        }
    })
], ProfileModule);
export { ProfileModule };
