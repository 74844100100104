// Copyright © 2021 Move Closer
import { MappingTypes } from '@movecloser/front-core';
import { authorAdapterMap } from '@content/models/author.adapter';
export const commentAdapterMap = {
    childrenCount: 'childrenCount',
    content: 'content',
    publicationDate: 'createdAt',
    creator: {
        type: MappingTypes.Adapter,
        map: authorAdapterMap,
        value: 'creator'
    },
    id: 'id',
    parentId: 'parentId',
    replies: {
        type: MappingTypes.Self,
        value: 'replies'
    }
};
