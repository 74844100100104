import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FooterBottom } from './FooterBottom';
import { FooterTop } from './FooterTop';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Footer = class Footer extends Vue {
    get appver() {
        var _a;
        return (_a = process.env.VUE_APP_VERSION) !== null && _a !== void 0 ? _a : '';
    }
};
__decorate([
    Prop({
        type: Array,
        required: false
    })
], Footer.prototype, "bottomLinks", void 0);
__decorate([
    Prop({
        type: Object,
        required: false
    })
], Footer.prototype, "mainLink", void 0);
__decorate([
    Prop({
        type: Array,
        required: false
    })
], Footer.prototype, "topLinks", void 0);
Footer = __decorate([
    Component({
        name: 'Footer',
        components: {
            FooterBottom,
            FooterTop
        }
    })
], Footer);
export { Footer };
export default Footer;
