// Copyright © 2021 Move Closer
import { mapModel, MappingTypes } from '@movecloser/front-core';
import { transactionAdapterMap } from './transaction.adapter';
import { PromotionProductType } from '@subscription/contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const subscriptionAdapterMap = {
    endedAt: 'endedAt',
    id: 'uuid',
    isPaid: {
        type: MappingTypes.Function,
        value: item => (item.isPaid || false)
    },
    period: 'period',
    price: 'price',
    promotion: {
        type: MappingTypes.Function,
        value: item => {
            if (item.successfulTransaction) {
                return { products: item.successfulTransaction.products };
            }
            if (!item.transaction || !item.transaction.promotionDetails) {
                return null;
            }
            const promo = {
                id: '',
                discount: -1,
                type: PromotionProductType.Subscription,
                finalPrice: item.transaction.total,
                message: item.transaction.promotionDetails.message,
                price: item.price,
                kind: ''
            };
            return {
                products: [promo]
            };
        }
    },
    renewable: 'renewable',
    startedAt: 'startedAt',
    transaction: {
        type: MappingTypes.Function,
        value: item => (item.transaction ? mapModel(item.transaction, transactionAdapterMap) : null)
    },
    type: 'type'
};
