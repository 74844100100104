// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Product extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'bestOffer', 'customParams', 'fullPrice', 'period', 'price', 'promo', 'renewable', 'saves', 'type'
        ];
    }
    toCreatePayload() {
        return {
            period: this._data.period,
            renewable: this._data.renewable,
            type: this._data.type
        };
    }
}
