// Copyright © 2021 Move Closer
import { createDecorator } from 'vue-class-component';
let Vue;
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Inject = (identifier) => (proto, key) => {
    let Type;
    if (typeof Reflect !== 'undefined' && typeof Reflect.getMetadata === 'function') {
        Type = Reflect.getMetadata('design:type', proto, key);
    }
    return createDecorator((options, key) => {
        options.computed = options.computed || {};
        options.computed[key] = function () {
            // @ts-expect-error
            return this.$container.get(identifier || Type);
        };
    })(proto, key);
};
function initInversify() {
    if (this.$options.container) {
        this.$container = this.$options.container;
    }
    else if (this.$options.parent && this.$options.parent.$container) {
        this.$container = this.$options.parent.$container;
    }
}
function install(_Vue) {
    if (Vue && _Vue === Vue) {
        if (process.env.NODE_ENV !== 'production') {
            /* eslint no-console: off */
            console.error('[inversify] already installed.');
        }
    }
    else {
        _Vue.mixin({ beforeCreate: initInversify });
    }
}
export default { install };
