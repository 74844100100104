// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
export class FeedEntry extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'content',
            'createdAt',
            'deletedAt',
            'embed',
            'feedId',
            'gallery',
            'id',
            'image',
            'isNew',
            'updatedAt'
        ];
    }
}
