// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { Card } from '../models/card';
import { cardAdapterMap } from '@profile/models/card.adapter';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let CardsRepository = class CardsRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = cardAdapterMap;
        this.useAdapter = true;
    }
    async add(payload) {
        var _a;
        const response = await this.connector.call('cards', 'add', {}, { driverPayload: { ...payload } });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
        return {
            message: response.data.message,
            redirect: response.data.data.redirect
        };
    }
    async load() {
        var _a;
        const response = await this.connector.call('cards', 'load', {}, {});
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
        return this.composeCollection(response.data.me.cards, Card, {});
    }
    async remove(id) {
        var _a;
        const response = await this.connector.call('cards', 'remove', { id }, {});
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
    }
    async update(id) {
        var _a;
        const response = await this.connector.call('cards', 'update', { id }, {});
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
    }
};
CardsRepository = __decorate([
    Injectable()
], CardsRepository);
export { CardsRepository };
