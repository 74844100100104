import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Adrian Kluska <adrian.kluska@movecloser.pl>
 */
let Screen = class Screen extends Vue {
    constructor() {
        super(...arguments);
        this.isOffScreen = false;
        this.isOpen = false;
    }
    /**
     * Vue lifecycle hook.
     */
    beforeDestroy() {
        this.isOpen = false;
    }
    /**
     * Vue lifecycle hook.
     */
    mounted() {
        this.$nextTick(() => {
            if (this.delayed) {
                setTimeout(this.open, 250);
            }
            else {
                this.open();
            }
        });
    }
    open() {
        this.isOpen = true;
        if (typeof window === 'undefined' ||
            (this.$el.getBoundingClientRect().height > window.innerHeight)) {
            this.isOffScreen = true;
        }
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Screen.prototype, "delayed", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Screen.prototype, "hideOnLoading", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Screen.prototype, "loading", void 0);
Screen = __decorate([
    Component({ name: 'Screen' })
], Screen);
export { Screen };
export default Screen;
