// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Content extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'author', 'contentId', 'categoryUrl', 'options', 'parent', 'pdf', 'properties', 'publicationDate', 'site', 'slots', 'title', 'type', 'urlPath', 'fullUrl'
        ];
    }
    /**
     * Return configuration of Content.
     */
    getOption(key, defaultValue = false) {
        var _a;
        return (_a = this._data.options[key]) !== null && _a !== void 0 ? _a : defaultValue;
    }
    /**
     * Return Addon properties.
     */
    getProperty(addon) {
        if (!this.hasProperty(addon)) {
            return {};
        }
        return this._data.properties[addon];
    }
    /**
     * Check if model has given Addon set.
     */
    hasProperty(addon) {
        return typeof this._data.properties[addon] !== 'undefined' && this._data.properties[addon] !== null;
    }
}
