import { AbstractEvent } from '@kluseg/trackify/dist/main';
export class SearchLoadEvent extends AbstractEvent {
    constructor(config) {
        super(config);
        this.config = config;
        this.name = 'custom.search_load';
    }
    forDriver(driverName) {
        if (driverName === 'GTMBrowserDriver') {
            return {
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_name: 'search_load',
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_payload: this.getData(),
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_type: 'common'
            };
        }
        return null;
    }
    getData() {
        return { ...this.config };
    }
}
