// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const payments = {
    payu: {
        posId: process.env.VUE_APP_PAYU_POSID,
        // eslint-disable-next-line eqeqeq
        sandbox: `${process.env.VUE_APP_PAYU_SANDBOX}` == 'true'
    }
};
