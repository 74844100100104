var Sidebar_1;
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { EventbusType, ModalType } from '@movecloser/front-core';
import { EModal } from '@contract/modal';
import { NavigationServiceType, NavigationSlot } from '@service/navigation';
import { Inject } from '@plugin/inversify';
import { SidebarNav } from './SidebarNav';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let Sidebar = Sidebar_1 = class Sidebar extends Vue {
    constructor() {
        super(...arguments);
        /**
         * Determines whether the sidebar is currently open.
         */
        this.isOpen = false;
    }
    /**
     * Determines whether the User is currently logged-in.
     */
    get isUserLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    get mainLinks() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.Left)
            ? ((_a = this.navigation.getSlot(NavigationSlot.Left)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get relatedSitesLinks() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.RelatedSites)
            ? ((_a = this.navigation.getSlot(NavigationSlot.RelatedSites)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get shouldRenderSubscriptionBtn() {
        return this.navigation.getSubscriptionButtonState();
    }
    get socialLinks() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.Social)
            ? ((_a = this.navigation.getSlot(NavigationSlot.Social)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    get sitesList() {
        return [];
    }
    /**
     * Vue lifecycle hook.
     */
    mounted() {
        this.attachListeners();
    }
    /**
     * Handles the `@click` event on the "log-in" button.
     */
    onLogInBtnClick() {
        this.modalConnector.open(EModal.LogIn);
    }
    /**
     * Attaches all the needed event listeners.
     */
    attachListeners() {
        this.eventBus.handle('ui:sidebar.close', this.close);
        this.eventBus.handle('ui:sidebar.open', this.open);
        this.eventBus.handle('ui:sidebar.toggle', this.toggle);
    }
    /**
     * Closes the sidebar.
     */
    close() {
        if (this.isOpen) {
            this.isOpen = false;
            document.body.classList.remove('--open');
            Sidebar_1.unlockBodyScroll();
            this.litMainContent();
            this.eventBus.emit('ui:sidebar.changed', { isOpen: false });
        }
    }
    /**
     * Dims main content of the page.
     */
    dimMainContent() {
        this.eventBus.emit('ui:layout.dim');
    }
    /**
     * Lits main content of the page up.
     */
    litMainContent() {
        this.eventBus.emit('ui:layout.lit');
    }
    /**
     * Prevents the `<body>` from scrolling.
     */
    static lockBodyScroll() {
        const root = document.getElementsByTagName('html')[0];
        const value = `${root.getAttribute('class')} overflow-hidden`;
        root.setAttribute('class', value);
    }
    /**
     * Opens the sidebar.
     */
    open() {
        this.isOpen = true;
        document.body.classList.add('--open');
        Sidebar_1.lockBodyScroll();
        this.dimMainContent();
        this.eventBus.emit('ui:sidebar.changed', { isOpen: true });
    }
    /**
     * Toggles (opens/closes) the sidebar.
     */
    toggle() {
        this.isOpen ? this.close() : this.open();
    }
    /**
     * Re-enables the `<body>` scroll.
     */
    static unlockBodyScroll() {
        const root = document.getElementsByTagName('html')[0];
        const value = `${root.getAttribute('class')}`;
        const lClass = value.split(' ');
        lClass.pop();
        root.setAttribute('class', lClass.join(''));
    }
};
__decorate([
    Inject(EventbusType)
], Sidebar.prototype, "eventBus", void 0);
__decorate([
    Inject(ModalType)
], Sidebar.prototype, "modalConnector", void 0);
__decorate([
    Inject(NavigationServiceType)
], Sidebar.prototype, "navigation", void 0);
Sidebar = Sidebar_1 = __decorate([
    Component({
        name: 'Sidebar',
        components: {
            SidebarNav
        }
    })
], Sidebar);
export { Sidebar };
export default Sidebar;
