// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { ApiConnectorType, AppModule, ModalType, Module } from '@movecloser/front-core';
import { resources } from './resources';
import { modalsRegistry } from '@config/modal';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let RootModule = class RootModule extends Module {
};
RootModule = __decorate([
    AppModule({
        name: 'root',
        boot: (container) => {
            const connector = container.get(ApiConnectorType);
            connector.useResources(resources);
            const modalConnector = container.get(ModalType);
            modalConnector.register(modalsRegistry);
        }
    })
], RootModule);
export { RootModule };
