// Copyright © 2021 Move Closer
import trimFilter from './extensions/trim';
/**
 * Register all Vue extensions.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @version 1.0.0
 */
export function registerExtensions(Vue) {
    const extensions = {
        filters: {
            trim: trimFilter
        }
    };
    // Register all custom filters
    for (const [name, filter] of Object.entries(extensions.filters)) {
        Vue.filter(name, filter);
    }
}
