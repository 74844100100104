import { __decorate } from "tslib";
import { Component, Ref, Vue } from 'vue-property-decorator';
import { SiteServiceType } from '@service/site';
import { Inject } from '@plugin/inversify';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SearchBar = class SearchBar extends Vue {
    constructor() {
        super(...arguments);
        this.isSearchActive = false;
        this.model = '';
        this.sort = true;
        this.tabs = [{
                id: 'all',
                label: 'Wszystko'
            }];
    }
    get activeTab() {
        if (this.queryParams.label && typeof this.queryParams.label === 'string' && this.tabs.map(tab => tab.id).includes(this.queryParams.label)) {
            return this.queryParams.label;
        }
        return 'all';
    }
    set activeTab(value) {
        this.$router.push({
            name: 'content.search',
            params: {},
            query: {
                ...this.queryParams,
                label: value
            }
        });
    }
    get queryParams() {
        return this.$route.query;
    }
    search() {
        const sortValue = (this.sort) ? '-date' : '';
        if (this.model && this.model.length > 0 && (this.queryParams.q !== this.model || this.queryParams.sort !== sortValue)) {
            this.$router.push({
                name: 'content.search',
                query: {
                    q: this.model,
                    label: 'all',
                    sort: sortValue
                }
            });
            this.isSearchActive = true;
        }
    }
    addEventListener() {
        if (!window || !document) {
            return;
        }
        document.addEventListener('keydown', this.onEnterClick);
    }
    composeTabs() {
        const activeSite = this.siteService.getActiveSite();
        if (activeSite && activeSite.searchLabels) {
            this.tabs.push(...activeSite.searchLabels.map(label => {
                return {
                    id: `${label.id}`,
                    label: label.name
                };
            }));
        }
    }
    onEnterClick(event) {
        if (event.key !== 'Enter' || !this.model) {
            return;
        }
        this.search();
    }
    setInputFocus() {
        if (!this.searchInput) {
            return;
        }
        const input = this.searchInput.$el.getElementsByTagName('input')[0];
        if (!input) {
            return;
        }
        input.focus();
    }
    validateQuery() {
        if (this.isSearchActive && typeof this.queryParams.label !== 'string') {
            this.$router.push({
                name: 'content.search',
                params: {},
                query: {
                    ...this.queryParams,
                    label: 'all'
                }
            });
        }
    }
};
__decorate([
    Inject(SiteServiceType)
], SearchBar.prototype, "siteService", void 0);
__decorate([
    Ref('searchInput')
], SearchBar.prototype, "searchInput", void 0);
SearchBar = __decorate([
    Component({
        name: 'SearchBar',
        created() {
            this.composeTabs();
            this.validateQuery();
            this.addEventListener();
            if (this.$route.name === 'content.search') {
                if (!!this.$route.query.q && typeof this.$route.query.q === 'string') {
                    this.model = this.$route.query.q;
                }
                if (!!this.$route.query.sort && typeof this.$route.query.sort === 'string') {
                    this.sort = (this.$route.query.sort === '-date');
                }
            }
        },
        mounted() {
            this.setInputFocus();
            this.isSearchActive = true;
        },
        beforeDestroy() {
            if (!document) {
                return;
            }
            document.removeEventListener('keydown', this.onEnterClick);
        }
    })
], SearchBar);
export { SearchBar };
export default SearchBar;
