// Copyright © 2021 Move Closer
/**
 * Opens pop-up window under the specified URL.
 *
 * @param url - The URL to be opened.
 * @param config - Pop-up configuration.
 * @param windowService - An instance of a class implementing IWindow interface.
 * @param [position] - Position of the pop-up.
 *   If not specified, the pop-up will be centered.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const openPopUp = (url, config, windowService, position) => {
    // Create a default configuration for the pop-up window.
    config = {
        personalbar: '0',
        resizable: '0',
        scrollbars: '0',
        toolbar: '0',
        // This statement will override any duplicate keys from above.
        ...config
    };
    /**
     * `windowFeatures` as `string[]` instead of just `string`.
     */
    const features = [];
    // Construct the array of `windowFeatures`.
    for (const configKey in config) {
        if (Object.prototype.hasOwnProperty.call(config, configKey)) {
            features.push(`${configKey}=${config[configKey]}`);
        }
    }
    /**
     * Position of the pop-up on the X axis.
     */
    let left;
    /**
     * Position of the pop-up on the Y axis.
     */
    let top;
    if (typeof position === 'object' &&
        Object.prototype.hasOwnProperty.call(position, 'top') &&
        typeof position.top === 'number' &&
        Object.prototype.hasOwnProperty.call(position, 'left') &&
        typeof position.left === 'number') {
        // CASE A: The pop-up position has been specified explicitly.
        top = position.top;
        left = position.left;
    }
    else {
        // CASE B: The pop-up position has NOT been specified.
        // The pop-up window will be centered.
        // @see - https://stackoverflow.com/a/16861050/11869579
        // @see - http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
        const screenWidth = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
        const screenHeight = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;
        const systemZoom = screenWidth / window.screen.availWidth;
        // Resolve width and height of the pop-up.
        const { height, width } = config;
        left = (screenWidth - Number.parseInt(width)) / 2 / systemZoom + dualScreenLeft;
        top = (screenHeight - Number.parseInt(height)) / 2 / systemZoom + dualScreenTop;
    }
    // Inject the `windowFeatures` for the `left` and `top` parameters.
    features.push(`left=${left}`);
    features.push(`top=${top}`);
    windowService.call('open', [url, '', features.join(',')]);
};
