import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
import { log } from '@support/log';
/**
 * NewsletterService
 *
 * This class is responsible to handle subscriptions, mirroring
 * and other actions related to Newsletter
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @inheritDoc
 */
let UserComService = class UserComService {
    constructor(connector, siteService) {
        this.key = (process.env.VUE_APP_USER_COM_API_KEY || '');
        this.url = (process.env.VUE_APP_USER_COM_API_ENDPOINT || '');
        this.connector = connector;
        this.siteService = siteService;
    }
    /**
     *
     * @inheritDoc
     */
    initializeUserComScript() {
        this.loadScript().then(() => {
            /**
             * Piece of executable string that should be triggered once the main
             * script from loadScript method is ready
             */
            const _executable = `
      window.civchat = {
        apiKey: "${this.key}",
      };
      `;
            /**
             * Define another script tag to place above executable code
             * @author Javlon Khalimjonov <javlon.khalimjonov@mocevloser.pl>
             */
            const userScript = document.createElement('script');
            userScript.setAttribute('data-cfasync', 'false');
            userScript.type = 'text/javascript';
            userScript.innerHTML = _executable;
            document.body.appendChild(userScript);
        });
    }
    /**
     * @inheritDoc
     */
    async requestAccess(eventName, formData) {
        await this.backupData('requestAccess', formData);
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const userengage = window.userengage;
            if (typeof userengage === 'undefined') {
                reject(new Error('User.com not defined'));
            }
            userengage(`event.${eventName}`, formData)
                .then(() => resolve())
                .catch((e) => reject(e));
        });
    }
    /**
     * @inheritDoc
     */
    async sendContactForm(formData) {
        await this.backupData('contact', formData);
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const userengage = window.userengage;
            if (typeof userengage === 'undefined') {
                reject(new Error('User.com not defined'));
            }
            userengage('event.contact', formData)
                .then(() => resolve())
                .catch((e) => reject(e));
        });
    }
    /**
     * @inheritDoc
     */
    async subscribe(formData) {
        const data = {
            ...formData,
            ...{ domain: this.siteService.getActiveSite().domain }
        };
        await this.backupData('subscribe', data);
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const userengage = window.userengage;
            if (typeof userengage === 'undefined') {
                reject(new Error('User.com not defined'));
            }
            userengage('client.update', { email: formData.email }).then(() => {
                userengage('event.subscribe', data)
                    .then(() => resolve())
                    .catch((e) => reject(e));
            }).catch((e) => reject(e));
        });
    }
    /**
     * Backup data sent to user.com service.
     *
     * @param event
     * @param data
     * @param reThrow
     * @protected
     */
    async backupData(event, data, reThrow = true) {
        try {
            await this.connector.call('backup', 'backupData', {}, {
                eventName: event,
                payload: data
            });
        }
        catch (e) {
            log(e.message, 'warn');
            if (reThrow) {
                throw e;
            }
        }
    }
    /**
     * Asynchronously loads a script and injects it to DOM.
     *
     * @private
     */
    loadScript() {
        return new Promise((resolve, reject) => {
            let el = document.querySelector(`script[src="${this.url}"]`);
            if (!el) {
                el = document.createElement('script');
                el.setAttribute('data-cfasync', 'false');
                el.src = this.url;
                el.type = 'text/javascript';
            }
            if (el.hasAttribute('data-loaded')) {
                resolve(Boolean(el.getAttribute('data-loaded')));
                return;
            }
            el.addEventListener('error', reject);
            el.addEventListener('abort', reject);
            el.addEventListener('load', function () {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                el.setAttribute('data-loaded', 'true');
                resolve(true);
            });
            document.body.appendChild(el);
        });
    }
    async sendEvent(eventName, formData) {
        await this.backupData(eventName, formData);
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const userengage = window.userengage;
            if (typeof userengage === 'undefined') {
                reject(new Error('User.com not defined'));
            }
            userengage(`event.${eventName}`, formData)
                .then(() => resolve())
                .catch((e) => reject(e));
        });
    }
};
UserComService = __decorate([
    Injectable()
], UserComService);
export { UserComService };
