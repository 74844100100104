// Copyright © 2021 Move Closer
import { Methods } from '@movecloser/front-core';
/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resources = {
    auth: {
        connection: 'd24',
        prefix: 'access',
        methods: {
            activateAccount: {
                url: 'account/activation',
                method: Methods.Post
            },
            createAccount: {
                url: 'account',
                method: Methods.Post
            },
            forgotPassword: {
                url: 'password/send',
                method: Methods.Post
            },
            logIn: {
                url: 'token',
                method: Methods.Post
            },
            logInWithSocial: {
                url: 'token',
                method: Methods.Get
            },
            logOut: {
                url: 'token',
                method: Methods.Delete,
                auth: true
            },
            refreshToken: {
                url: 'token/refresh',
                method: Methods.Post,
                auth: true
            },
            resetPassword: {
                url: 'password/reset',
                method: Methods.Post
            }
        }
    },
    me: {
        connection: 'd24',
        prefix: 'me',
        methods: {
            get: {
                url: '',
                method: Methods.Get,
                auth: true
            },
            update: {
                url: 'profile',
                method: Methods.Put,
                auth: true,
                shorthand: 'updateProfileForm'
            },
            setAvatar: {
                url: 'avatar',
                method: Methods.Post,
                auth: true,
                shorthand: 'setAvatarForm'
            },
            changePassword: {
                url: 'password',
                method: Methods.Put,
                auth: true,
                shorthand: 'changePasswordForm'
            },
            articles: {
                url: 'articles',
                method: Methods.Get,
                auth: true
            }
        }
    },
    cards: {
        connection: 'd24',
        prefix: 'me/cards',
        methods: {
            add: {
                url: '',
                method: Methods.Post,
                auth: true,
                shorthand: 'manageCardsForm'
            },
            load: {
                url: '',
                method: Methods.Get,
                auth: true
            },
            remove: {
                url: '{id}',
                params: ['id'],
                method: Methods.Delete,
                auth: true
            },
            update: {
                url: '{id}',
                params: ['id'],
                method: Methods.Put,
                auth: true
            }
        }
    }
};
