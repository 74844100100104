// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const transactionAdapterMap = {
    driver: 'driver',
    startedAt: 'startedAt',
    status: 'status',
    token: 'token',
    total: 'total'
};
