// Copyright © 2021 Move Closer
import { ApiConnectorType, AuthServiceType, 
// CrossDomainLocalStorageProvider,
DateTime, DateTimeType, 
// LocalStorageType,
ModalType, WindowType } from '@movecloser/front-core';
import { IdentityMiddleware, IdentityMiddlewareType, IdentityService, IdentityServiceType } from '@service/identity';
import { NavigationService, NavigationServiceType } from '@service/navigation';
import { PaywallService, PaywallServiceType } from '@service/paywall';
import { PdfService, PdfServiceType } from '@service/pdf';
import { RelatedMiddleware, RelatedMiddlewareType, RelatedService, RelatedServiceType, relatedTypeDriverRegistryFactory } from '@service/related';
import { SetupService, SetupServiceType } from '@service/setup';
import { ShareService, ShareServiceType } from '@service/share';
import { SiteMiddleware, SiteMiddlewareType, SiteService, SiteServiceType } from '@service/site';
import { UserComService, UserComServiceType } from '@service/usercom';
import { shareServiceConfig } from '@config/share';
import { SubscriptionService, SubscriptionServiceType } from '@subscription/shared/services';
import { SubscriptionsRepositoryType } from '@subscription/contracts';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const services = (config) => {
    return (bind) => {
        bind(IdentityMiddlewareType).toDynamicValue(context => new IdentityMiddleware(context.container.get(IdentityServiceType))).inSingletonScope();
        bind(RelatedMiddlewareType).toDynamicValue(context => new RelatedMiddleware(context.container.get(RelatedServiceType))).inSingletonScope();
        bind(SiteMiddlewareType).toDynamicValue(context => new SiteMiddleware(context.container.get(SiteServiceType))).inSingletonScope();
        bind(IdentityServiceType).to(IdentityService).inSingletonScope();
        bind(NavigationServiceType).to(NavigationService).inSingletonScope();
        // bind<ILocalStorage>(LocalStorageType).toDynamicValue(() => {
        //   return new CrossDomainLocalStorageProvider(config.byKey('localStorage', false, undefined) ?? undefined)
        // }).inSingletonScope()
        bind(UserComServiceType).toDynamicValue(context => new UserComService(context.container.get(ApiConnectorType), context.container.get(SiteServiceType)));
        bind(PaywallServiceType).toDynamicValue(context => new PaywallService(context.container.get(ApiConnectorType)));
        bind(PdfServiceType).toDynamicValue(context => new PdfService(context.container.get(ApiConnectorType), context.container.get(ModalType), context.container.get(AuthServiceType)));
        bind(RelatedServiceType).toDynamicValue(() => new RelatedService(relatedTypeDriverRegistryFactory()));
        bind(SetupServiceType).toDynamicValue(context => new SetupService(context.container.get(ApiConnectorType), context.container.get(IdentityServiceType), context.container.get(NavigationServiceType), context.container.get(SiteServiceType))).inSingletonScope();
        bind(SiteServiceType).to(SiteService).inSingletonScope();
        bind(ShareServiceType).toDynamicValue(context => new ShareService(shareServiceConfig, context.container.get(ModalType), context.container.get(WindowType), context.container.get(SiteServiceType))).inSingletonScope();
        bind(SubscriptionServiceType).toDynamicValue(context => new SubscriptionService(context.container.get(SubscriptionsRepositoryType))).inSingletonScope();
        bind(DateTimeType).to(DateTime).inSingletonScope();
    };
};
