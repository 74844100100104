// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
let SubscriptionService = class SubscriptionService {
    constructor(subscriptions) {
        // public subscription: SubscriptionModel | null = null
        this.subscription = null;
        this.subscriptionType = null;
        this.subscriptionId = null;
        this.subscriptions = subscriptions;
    }
    async setSubscriptionData(subscriptionId, subscriptionType, subscriptionPeriod) {
        this.subscriptionId = subscriptionId;
        this.subscriptionType = subscriptionType;
        this.subscription = subscriptionId && subscriptionPeriod && subscriptionType ? {
            id: subscriptionId,
            type: subscriptionType,
            period: subscriptionPeriod
        } : null;
        // try {
        //  this.subscription = this.subscriptionId ? await this.subscriptions.load(this.subscriptionId) : null
        // } catch (e) {
        //   log(e, 'warn')
        // }
    }
};
SubscriptionService = __decorate([
    Injectable()
], SubscriptionService);
export { SubscriptionService };
