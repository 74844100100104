// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { Comment } from '../models/comment';
import { commentAdapterMap } from '../models/comment.adapter';
/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let CommentRepository = class CommentRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = commentAdapterMap;
        this.useAdapter = true;
    }
    async addComment(payload) {
        var _a;
        const response = await this.connector.call('comments', 'add', {}, payload);
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), response.data.errors);
        }
        return this.composeModel({
            ...response.data.data,
            ...payload
        }, Comment);
    }
    async deleteComment(commentId) {
        var _a;
        const response = await this.connector.call('comments', 'delete', { id: commentId });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), response.data.data);
        }
    }
    async loadCollection(contentId, authorizationHeader, query = {}) {
        var _a;
        const response = await this.connector.call('comments', 'list', {}, { contentId: contentId, ...query }, { ...authorizationHeader });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), response.data.data);
        }
        return this.composeCollection(response.data.data, Comment, response.data.meta);
    }
};
CommentRepository = __decorate([
    Injectable()
], CommentRepository);
export { CommentRepository };
