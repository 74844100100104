import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=060d7fab&"
import script from "./SearchBar.vue?vue&type=script&lang=ts&"
export * from "./SearchBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports