// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { ErrorDetails } from '@component/ErrorDetails/ErrorDetails.vue';
import { Layout } from '@component/Layout/Layout.vue';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let ClearLayout = class ClearLayout extends Vue {
    get hasErrors() {
        return this.$errorHandler.error !== null;
    }
    /**
     * Determine if Layout uses Sidebar.
     */
    static hasSidebar() {
        return false;
    }
};
ClearLayout = __decorate([
    Component({
        name: 'ClearLayout',
        components: { ErrorDetails, Layout },
        template: `
    <Layout>
    <template slot="middle">
      <ErrorDetails v-if="hasErrors" />
      <router-view v-else :key="$route.fullPath" />
    </template>
    </Layout>
  `
    })
], ClearLayout);
export { ClearLayout };
