// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
import { log } from '@support/log';
/**
 * Middleware that intercepts API's responses,
 * extracts the `meta` field and passes it to the `RelatedService`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let RelatedMiddleware = class RelatedMiddleware {
    constructor(relatedService) {
        this.relatedService = relatedService;
    }
    afterCall(response) {
        var _a, _b;
        log('🏷  RelatedMiddleware.afterCall()');
        const isRegistry = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.isRegistry;
        log(['response.data.meta.isRegistry:', isRegistry]);
        if (!isRegistry) {
            log('RelatedRecord will NOT be updated');
            return;
        }
        const record = response.data.meta;
        log('RelatedRecord WILL be updated');
        log(['record:', Object.entries(record)
                .reduce((acc, [key, value]) => {
                return {
                    ...acc,
                    [key]: typeof value === 'object'
                        ? Object.keys(value).length
                        : value
                };
            }, {})
        ]);
        this.relatedService.storeRelated(record);
    }
    beforeCall(resource, headers, body) {
        log('🏷  RelatedMiddleware.beforeCall()');
        log(['resource.url:', resource.url]);
        return { body, headers };
    }
};
RelatedMiddleware = __decorate([
    Injectable()
], RelatedMiddleware);
export { RelatedMiddleware };
export const RelatedMiddlewareType = Symbol.for('RelatedMiddleware');
