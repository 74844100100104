// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { FeedEntry } from '@content/models/feed-entry';
import { feedEntryAdapterMap } from '@content/models/feed-entry.adapter';
/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
let FeedEntriesRepository = class FeedEntriesRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = feedEntryAdapterMap;
        this.useAdapter = true;
    }
    async entries(id, query = {}) {
        var _a;
        const response = await this.connector.call('feeds', 'entries', { id }, {
            ...query
        });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response));
        }
        return this.composeCollection(response.data.data, FeedEntry, response.data.meta);
    }
    async single(id, entry) {
        var _a;
        const response = await this.connector.call('feeds', 'entry', { id, entry });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response));
        }
        return {
            model: this.composeModel(response.data.data, FeedEntry),
            meta: response.data.meta
        };
    }
};
FeedEntriesRepository = __decorate([
    Injectable()
], FeedEntriesRepository);
export { FeedEntriesRepository };
