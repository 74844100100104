// Copyright © 2021 Move Closer
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export var PaywallAction;
(function (PaywallAction) {
    PaywallAction["Login"] = "login";
    PaywallAction["None"] = "none";
    PaywallAction["Subscription"] = "subscription";
})(PaywallAction || (PaywallAction = {}));
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export var PaywallMode;
(function (PaywallMode) {
    PaywallMode["AccessGranted"] = "accessGranted";
    PaywallMode["AccessRestricted"] = "accessRestricted";
    PaywallMode["LimitReached"] = "limitReached";
    PaywallMode["SubscriptionRequired"] = "subscriptionRequired";
})(PaywallMode || (PaywallMode = {}));
export const PaywallServiceType = Symbol.for('IPaywallService');
