// Copyright © 2021 Move Closer
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export var EShareService;
(function (EShareService) {
    EShareService["CopyLink"] = "copy-link";
    EShareService["Email"] = "email";
    EShareService["Facebook"] = "facebook";
    EShareService["Instagram"] = "instagram";
    EShareService["Linkedin"] = "linkedin";
    EShareService["Twitter"] = "twitter";
})(EShareService || (EShareService = {}));
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const ShareServiceType = Symbol.for('IShareService');
