// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
import { User } from './user';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Token extends Model {
    boot() {
        this.initialValues = {
            tokenType: 'Bearer',
            expiresIn: null
        };
        this.modelProperties = ['accessToken', 'tokenType', 'expiresAt', 'me'];
    }
    getUser() {
        return this._data.me;
    }
    toAuthToken() {
        return {
            accessToken: this._data.accessToken,
            expiresAt: this._data.expiresAt,
            tokenType: this._data.tokenType
        };
    }
    /**
     * Returns relation to User model.
     */
    relatesToMe(value) {
        return this.hasOne(User, value);
    }
}
