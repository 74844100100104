// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
import { log } from '@support/log';
/**
 * Middleware that decorates the API calls with the information about the currently-active domain.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let SiteMiddleware = class SiteMiddleware {
    constructor(siteService) {
        this.sites = siteService;
    }
    afterCall() {
        //
    }
    beforeCall(resource, headers, body) {
        if (typeof resource.meta.omitSite === 'boolean' && resource.meta.omitSite) {
            return { body, headers };
        }
        log(['🏷 SiteMiddleware.beforeCall():', resource.url, this.domainHeader]);
        return {
            body,
            headers: {
                ...headers,
                ...this.domainHeader
            }
        };
    }
    get domainHeader() {
        const site = this.sites.getActiveSite();
        const fallbackLocale = process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB';
        return { 'Accept-Language': site.locale || fallbackLocale, 'X-Site': `${site.id}` };
    }
};
SiteMiddleware = __decorate([
    Injectable()
], SiteMiddleware);
export { SiteMiddleware };
