// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, mapModel } from '@movecloser/front-core';
import { NavigationSlot } from './navigation.contracts';
import { Navigation } from './navigation';
import { navigationAdapterMap } from './navigation.adapter';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let NavigationService = class NavigationService {
    constructor() {
        this.slots = {};
        this.subscriptionButtonState = true;
        this.premiumButtonLink = '';
    }
    getSlot(slot) {
        return this.slots[slot] || null;
    }
    hasSlot(slot) {
        return !!this.slots[slot];
    }
    /**
     * Gets the state of `Subscribe button`
     */
    getSubscriptionButtonState() {
        return this.subscriptionButtonState;
    }
    getPremiumButtonLink() {
        return this.premiumButtonLink;
    }
    /**
     * Sets the state of `Subscribe` button (true/false)
     * @param btnState
     */
    setSubscriptionButtonState(btnState) {
        this.subscriptionButtonState = !btnState;
    }
    setPremiumButtonLink(link) {
        this.premiumButtonLink = link;
    }
    parseNavigations(navs) {
        for (const slot of Object.values(NavigationSlot)) {
            if (typeof navs[slot] !== 'object' || !navs[slot]) {
                continue;
            }
            this.slots[slot] = Navigation.hydrate(mapModel(navs[slot], navigationAdapterMap));
        }
    }
};
NavigationService = __decorate([
    Injectable()
], NavigationService);
export { NavigationService };
