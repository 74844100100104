// Copyright © 2021 Move Closer
import { Model } from '@movecloser/front-core';
export class LiveFeed extends Model {
    boot() {
        this.initialValues = {};
        this.modelProperties = [
            'createdAt',
            'endedAt',
            'startedAt',
            'status',
            'title',
            'reverseOrder',
            'updatedAt'
        ];
    }
}
