// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { resolveFromStatus } from '@exception/connector-errors';
import { Transaction } from '../models/transaction';
import { transactionAdapterMap } from '../models/transaction.adapter';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let TransactionsRepository = class TransactionsRepository extends Repository {
    constructor() {
        super(...arguments);
        this.map = transactionAdapterMap;
        this.useAdapter = true;
    }
    async begin(subscription, id, payload, userData, companyData, promoCode = null) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'begin', {
            subscription,
            id
        }, {
            driverPayload: payload,
            companyData: {
                firstName: userData.firstName,
                lastName: userData.lastName,
                ...(companyData !== null && companyData !== void 0 ? companyData : null)
            },
            promoCode: promoCode
        });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return response.data.data;
    }
    async beginUpgrade(subscription, period) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'beginUpgrade', {}, { uuid: subscription, period });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return response.data.data;
    }
    async upgrade(subscription, driver, payload, userData, companyData, promoCode = null) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'upgrade', {
            subscription
        }, {
            driver,
            driverPayload: payload,
            companyData: {
                firstName: userData.firstName,
                lastName: userData.lastName,
                ...(companyData !== null && companyData !== void 0 ? companyData : null)
            },
            promoCode: promoCode
        });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return response.data.data;
    }
    async register(subscription, driver, force = false) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'register', { id: subscription }, {
            driver,
            force
        });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        if (!response.data.data || !('transactionToken' in response.data.data)) {
            return '';
        }
        return response.data.data.transactionToken;
    }
    async paywall(pageId, driver, payload, userData, companyData, promoCode = null) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'paywall', {
            pageId,
            driver
        }, {
            driverPayload: payload,
            companyData: {
                firstName: userData.firstName,
                lastName: userData.lastName,
                ...(companyData !== null && companyData !== void 0 ? companyData : null)
            },
            promoCode: promoCode
        });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), response.status === 409 ? response.data.data : (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return response.data.data;
    }
    async status(id) {
        var _a, _b;
        const response = await this.connector.call('subscriptions', 'status', { id });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response.status), (_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors);
        }
        return this.composeModel(response.data.data, Transaction);
    }
};
TransactionsRepository = __decorate([
    Injectable()
], TransactionsRepository);
export { TransactionsRepository };
